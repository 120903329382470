/* eslint-disable import/no-cycle */
import featureActivity from '@/features/activity/_framework/store'
import featureAnnouncement from '@/features/announcement/_framework/store'
import featureCommittee from '@/features/committee/_framework/store'
import featureCommunication from '@/features/communication/_framework/store'
import featureDocument from '@/features/document/_framework/store'
import featureFinance from '@/features/finance/_framework/store'
import featureMeeting from '@/features/meeting/_framework/store'
import featureNotification from '@/features/notification/_framework/store'
import featureReport from '@/features/report/_framework/store'
import featureTeam from '@/features/team/_framework/store'
import featureUser from '@/features/user/_framework/store'
import featureUser2 from '@/features/user2/_framework/store'
import appConfig from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import appErrors from './appError'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig,
    app,
    appErrors,
    featureUser2,
    featureUser,
    featureCommittee,
    featureTeam,
    featureMeeting,
    featureFinance,
    featureCommunication,
    featureDocument,
    featureReport,
    featureActivity,
    featureAnnouncement,
    featureNotification,
  },
})
