import { v4 as uuidv4 } from 'uuid'

class ProblemDetail {
  constructor(instance = uuidv4(), status = '', title = '', detail = '', type = '') {
    this.instance = instance ?? uuidv4()
    this.status = status ?? ''
    this.title = title ?? ''
    this.detail = detail ?? ''
    this.type = type ?? ''
  }

  static createWithInstance(instance) {
    return new ProblemDetail(instance)
  }
}

export default ProblemDetail
