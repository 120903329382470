import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_DOCUMENT}/referencedata`,
      payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_DOCUMENT}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters)
  },

  // =========================================
  // feature
  // =========================================
  fetchFolder(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_DOCUMENT}/organizations/${payload.routeParameters.organizationId}/blobs/folders`,
      payload.queryParameters)
  },

  createFolder(ctx, payload) {
    return apiService.post(`${process.env.VUE_APP_API_PATH_DOCUMENT}/organizations/${payload.routeParameters.organizationId}/blobs/folders`,
      payload.queryParameters,
      payload.dto)
  },

  uploadBlobs(ctx, payload) {
    return apiService.postBlob(`${process.env.VUE_APP_API_PATH_DOCUMENT}/organizations/${payload.routeParameters.organizationId}/blobs`,
      payload.queryParameters,
      payload.dto)
  },

  downloadBlob(ctx, payload) {
    return apiService.getBlob(`${process.env.VUE_APP_API_PATH_DOCUMENT}/organizations/${payload.routeParameters.organizationId}/blobs`,
      payload.queryParameters)
  },

  downloadBlobs(ctx, payload) {
    return apiService.getBlobs(`${process.env.VUE_APP_API_PATH_DOCUMENT}/organizations/${payload.routeParameters.organizationId}/blobs/zip`,
      payload.queryParameters,
      payload.dto)
  },

  deleteBlobs(ctx, payload) {
    return apiService.delete(`${process.env.VUE_APP_API_PATH_DOCUMENT}/organizations/${payload.routeParameters.organizationId}/blobs`,
      payload.queryParameters,
      payload.dto)
  },
}
