import useApiError from '@/core/api/useApiError'
import constants from '@/features/activity/_framework/constants'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useActivity() {
  // =========================================
  // register module(s)
  // =========================================

  // =========================================
  // error handling
  // =========================================
  const {
    addError
  } = useApiError()

  // =========================================
  // user session context
  // =========================================
  const {
    organizationContext,
  } = useUser2SessionContext()

  // =========================================
  // feature
  // =========================================
  const activityList = ref([])
  const auditLogActivity = ref({})
  const eventLogActivity = ref({})
  const logActivity = ref({})

  // ==================
  // service
  // ==================
  const serviceSourceOptions = [
    {
      code: 0,
      name: 'Membership',
    },
    {
      code: 1,
      name: 'Committee',
    },
    {
      code: 2,
      name: 'Team',
    },
    {
      code: 3,
      name: 'Meeting',
    },
  ]
  const serviceSourceFilter = ref([])

  // ==================
  // source
  // ==================
  const activitySourceOptions = [
    {
      code: 0,
      name: 'AuditLog',
    },
    {
      code: 1,
      name: 'EventLog',
    },
    {
      code: 2,
      name: 'Log',
    },
  ]
  const activitySourceFilter = ref([])

  // ==================
  // type
  // ==================
  const auditLogActivityTypeOptions = [
    {
      code: 0,
      name: 'Contact',
    },
    {
      code: 1,
      name: 'Invoice',
    },
    {
      code: 2,
      name: 'Payment',
    },
    {
      code: 3,
      name: 'Meeting',
    },
    {
      code: 4,
      name: 'Invitation',
    },
  ]
  const eventLogActivityTypeOptions = [
    {
      code: 10,
      name: 'Email',
    },
    {
      code: 11,
      name: 'Notification',
    },
  ]
  const logActivityTypeOptions = [
    {
      code: 20,
      name: 'Verbose',
    },
    {
      code: 21,
      name: 'Debug',
    },
    {
      code: 22,
      name: 'Information',
    },
    {
      code: 23,
      name: 'Warning',
    },
    {
      code: 24,
      name: 'Error',
    },
    {
      code: 25,
      name: 'Fatal',
    },
  ]

  const activityTypeFilter = ref([])

  // ==================
  // time
  // ==================
  const customTimeOption = '99'

  const timeOptions = [
    {
      code: '1',
      name: 'last 1 hour',
    },
    {
      code: '2',
      name: 'last 24 hours',
    },
    {
      code: '3',
      name: 'last 30 days',
    },
    {
      code: '99',
      name: 'custom',
    },
  ]

  const timeFilter = ref('2')

  // =========================================
  // api
  // =========================================
  const fetchActivityList = async () => {
    try {
      const response = await store
        .dispatch(`${constants.ACTIVITY_STORE_MODULE_NAME}/fetchActivityList`,
          {
            routeParameters: {
              organizationId: organizationContext.value.organizationId,
            },
            queryParameters: { },
          })

      if (response.status === 200) {
        activityList.value = JSON.parse(JSON.stringify(response.data))

        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  const fetchAuditLogActivity = async auditLogId => {
    try {
      const response = await store
        .dispatch(`${constants.ACTIVITY_STORE_MODULE_NAME}/fetchAuditLogActivity`,
          {
            routeParameters: {
              organizationId: organizationContext.value.organizationId,
              auditLogId,
            },
            queryParameters: { },
          })

      if (response.status === 200) {
        auditLogActivity.value = JSON.parse(JSON.stringify(response.data))

        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  const fetchEventLogActivity = async eventLogId => {
    try {
      const response = await store
        .dispatch(`${constants.ACTIVITY_STORE_MODULE_NAME}/fetchEventLogActivity`,
          {
            routeParameters: {
              organizationId: organizationContext.value.organizationId,
              eventLogId,
            },
            queryParameters: { },
          })

      if (response.status === 200) {
        eventLogActivity.value = JSON.parse(JSON.stringify(response.data))

        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  const fetchLogActivity = async logId => {
    try {
      const response = await store
        .dispatch(`${constants.ACTIVITY_STORE_MODULE_NAME}/fetchLogActivity`,
          {
            routeParameters: {
              organizationId: organizationContext.value.organizationId,
              logId,
            },
            queryParameters: { },
          })

      if (response.status === 200) {
        logActivity.value = JSON.parse(JSON.stringify(response.data))

        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }
  
  const createErrorSubmission = async apiData => {
    try {
      const response = await store
        .dispatch(`${constants.ACTIVITY_STORE_MODULE_NAME}/createErrorSubmission`,
          {
            routeParameters: {
              organizationId: organizationContext.value.organizationId,
            },
            queryParameters: { },
            dto: apiData.dto,
          })

      if (response.status === 201) {
        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  // =========================================
  // watch
  // =========================================
  watch([activitySourceFilter], async (newValue, oldValue) => {
    await fetchActivityList()
  },
  { deep: true })

  watch([timeFilter], async (newValue, oldValue) => {
    if (timeFilter.value !== customTimeOption) {
      await fetchActivityList()
    }
  },
  { deep: true })

  // =========================================
  // return
  // =========================================
  return {
    serviceSourceOptions,
    serviceSourceFilter,

    activitySourceOptions,
    activitySourceFilter,

    auditLogActivityTypeOptions,
    eventLogActivityTypeOptions,
    logActivityTypeOptions,
    activityTypeFilter,

    timeOptions,
    timeFilter,
    customTimeOption,

    activityList,
    auditLogActivity,
    eventLogActivity,
    logActivity,

    fetchActivityList,
    fetchAuditLogActivity,
    fetchEventLogActivity,
    fetchLogActivity,
    createErrorSubmission,
  }
}
