const secondary = [
  // Member View
  {
    path: '/features/user/view/:id',
    name: 'feature-user-view',
    component: () => import('@/features/user/domain/pages/view/ContactViewPage.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Member List',
          to: { name: 'feature-user-list', path: 'user/list' },
        },
        {
          name: 'Member View',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-user-list',
      module: 'user',
      resource: 'Tenant',
    },
  },

  // Member Add
  {
    path: '/features/user/add',
    name: 'feature-user-add',
    component: () => import('@/features/user/domain/pages/add/AddContact.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Member List',
          to: { name: 'feature-user-list', path: 'user/list' },
        },
        {
          name: 'Member Add',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-user-list',
      module: 'user',
      resource: 'Tenant',
    },
  },

  // Member Report View
  {
    path: '/features/user/reports/view/:id',
    name: 'feature-user-report-view',
    component: () => import('@/features/user/reports/report-view/ReportView.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Membership Report List',
          exact: true,
          to: { name: 'feature-user-reports-list', path: 'user/reports/list' },
        },
        {

          name: 'Report View',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-user-reports-list',
      module: 'user',
      resource: 'Tenant',
    },
  },

  // Compose Email View
  {
    path: '/features/user/email/compose',
    name: 'feature-user-compose-email',
    component: () => import('@/features/user/communication/pages/compose/ComposeEmail.vue'),
    props: route => ({ envelopeId: route.query.env }),
    meta: {
      breadcrumb: [
        {
          name: 'Member List',
          to: { name: 'feature-user-list', path: 'user/list' },
        },
        {
          name: 'Compose Email',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-user-list',
      module: 'user',
      resource: 'Tenant',
    },
  },
]

export default secondary
