import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_REPORT}/referencedata`,
      payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_REPORT}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters)
  },

  // =========================================
  // feature
  // =========================================
  fetchReports(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_REPORT}/organizations/${payload.routeParameters.organizationId}/users/${payload.routeParameters.userId}/reports`,
      payload.queryParameters)
  },

  updateReport(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_REPORT}/organizations/${payload.routeParameters.organizationId}/users/${payload.routeParameters.userId}/reports/${payload.routeParameters.reportId}`,
      payload.queryParameters,
      payload.body)
  },
}
