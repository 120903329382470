import useApiError from '@/core/api/useApiError'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default function useAppEnvironmentContext() {
  // =========================================
  // variables
  // =========================================
  const featureName = 'app'
  const mutationName = `${featureName}/SET_APP_ENVIRONMENT_CONTEXT`
  const getterName = `${featureName}/getAppEnvironmentContext`
  const actionName = `${featureName}/fetchAppEnvironmentContext`

  // =========================================
  // error handling
  // =========================================
  const {
    addError,
  } = useApiError()

  // =========================================
  // feature
  // =========================================
  const appEnvironmentContext = computed({
    get: () => store.getters[getterName],
    set: newValue => {
      store.commit(mutationName, newValue)
    },
  })

  const isProduction = computed(() => appEnvironmentContext.value.environmentName.toLowerCase() === 'production')
  const isStaging = computed(() => appEnvironmentContext.value.environmentName.toLowerCase() === 'staging')
  const isDevelopment = computed(() => appEnvironmentContext.value.environmentName.toLowerCase() === 'development')
  const appEnvironmentName = computed(() => appEnvironmentContext.value.environmentName)

  // =========================================
  // api
  // =========================================
  const fetchAppEnvironmentContext = async () => {
    try {
      if (appEnvironmentContext.value && Object.keys(appEnvironmentContext.value).length > 0) {
        return true
      }

      const response = await store.dispatch(actionName, {
        routeParameters: {},
        queryParameters: {},
      })

      if (response.status === 200) {
        appEnvironmentContext.value = JSON.parse(JSON.stringify(response.data))

        return true
      }
      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  // =========================================
  // return
  // =========================================
  return {
    isProduction,
    isStaging,
    isDevelopment,
    appEnvironmentName,
    appEnvironmentContext,

    fetchAppEnvironmentContext,
  }
}
