import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_NOTIFICATION}/referencedata`,
      payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_NOTIFICATION}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters)
  },

  // =========================================
  // feature
  // =========================================
  dismissNotifications(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_NOTIFICATION}/organizations/${payload.routeParameters.organizationId}/users/${payload.routeParameters.userId}/notifications/dismiss`,
      payload.queryParameters,
      payload.dto,
      false)
  },

  fetchNotificationList(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_NOTIFICATION}/organizations/${payload.routeParameters.organizationId}/users/${payload.routeParameters.userId}/notifications`,
      payload.queryParameters,
      false)
  },
}
