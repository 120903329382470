const secondary = [
  {
    path: '/features/activity/view/:id',
    name: 'feature-activity-view',
    component: () => import('@/features/activity/domain/pages/view/ActivityView.vue'),
    props: route => ({ activityId: route.params.id, activitySource: route.query.activitySource }),
    meta: {
      breadcrumb: [{
        name: 'Activity List',
        exact: true,
        to: { name: 'feature-activity-list' },
      }, {

        name: 'Activity View',
      }],
      layout: 'content',
      navActiveLink: 'feature-activity-list',
      module: 'activity',
      resource: 'Tenant',
    },
  },
]

export default secondary
