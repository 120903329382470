const secondary = [
  // Create Meeting
  {
    name: 'feature-meeting-create',
    path: '/features/meeting/create',
    component: () => import('@/features/meeting/domain/pages/meeting-create/Create.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Meeting List',
          exact: true,
          to: { name: 'feature-meeting-list' },
        },
        {
          name: 'New',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-meeting-list',
      module: 'meeting',
      resource: 'Tenant',
    },
  },

  // View Meeting
  {
    name: 'feature-meeting-view',
    path: '/features/meeting/view/:meetingId',
    component: () => import('@/features/meeting/domain/pages/meeting-view/View.vue'),
    props: route => ({ meetingId: route.params.meetingId, mode: route.query.mode }),
    meta: {
      breadcrumb: [
        {
          name: 'Meeting List',
          exact: true,
          to: { name: 'feature-meeting-list' },
        },
        {
          name: 'Meeting View',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-meeting-list',
      module: 'meeting',
      resource: 'Tenant',
    },
  },

  // List Invitations
  {
    name: 'feature-meeting-invitation-list',
    path: '/features/meeting/:meetingId/invitation/list/',
    component: () => import('@/features/meeting/domain/pages/invitation-list/List.vue'),
    props: route => ({ meetingId: route.params.meetingId }),
    meta: {
      layout: 'content',
      module: 'meeting',
      resource: 'Tenant',
      navActiveLink: 'feature-meeting-list',
      breadcrumb: [
        {
          name: 'Meeting List',
          exact: true,
          to: { name: 'feature-meeting-list' },
        },
        {
          name: 'Meeting View',
          exact: true,
          to: { name: 'feature-meeting-view' },
          parameterMap: { id: 'meetingId' },
        },
        {
          name: 'Invitation List',
        },
      ],
    },
  },

  // Create Invitation
  {
    name: 'feature-invitation-create',
    path: '/features/meeting/:meetingId/invitation/create/',
    component: () => import('@/features/meeting/domain/pages/invitation-create/Create.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Meeting List',
          exact: true,
          to: { name: 'feature-meeting-list' },
        },
        {
          name: 'Meeting View',
          exact: true,
          to: { name: 'feature-meeting-view' },
          parameterMap: { id: 'meetingId' },
        },
        {
          name: 'Invitation List',
          exact: true,
          to: { name: 'feature-meeting-invitation-list' },
        },
        {
          name: 'New',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-meeting-list',
      module: 'meeting',
      resource: 'Tenant',
    },
  },

  // View RSVP
  {
    name: 'feature-meeting-rsvp-view',
    path: '/features/meeting/:meetingId/invitation/:invitationId/rsvp/view/',
    component: () => import('@/features/meeting/domain/pages/rsvp-response-view/View.vue'),
    props: route => ({
      meetingId: route.params.meetingId,
      invitationId: route.params.invitationId,
      mode: route.query.mode,
    }),
    meta: {
      breadcrumb: [
        {
          name: 'Meeting List',
          exact: true,
          to: { name: 'feature-meeting-list' },
        },
        {
          name: 'Meeting View',
          exact: true,
          to: { name: 'feature-meeting-view' },
          parameterMap: { id: 'meetingId' },
        },
        {
          name: 'Invitation List',
          exact: true,
          to: { name: 'feature-meeting-invitation-list' },
        },
        {
          name: 'RSVP',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-meeting-list',
      module: 'meeting',
      resource: 'Tenant',
    },
  },

  // View Member RSVP
  {
    path: '/features/meeting/memberrsvp',
    name: 'feature-meeting-member-rsvp-view',
    component: () => import('@/features/meeting/domain/pages/rsvp-member-response-view/Page.vue'),
    props: route => ({
      p: route.query.p,
    }),
    meta: {
      layout: 'blank',
      module: 'meeting',
      resource: 'Public',
    },
  },

  // View Report
  {
    name: 'feature-meeting-report-view',
    path: '/features/meeting/reports/view/:id',
    component: () => import('@/features/meeting/reports/report-view/ReportView.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Meeting Report List',
          exact: true,
          to: { name: 'feature-meeting-reports-list' },
        },
        {
          name: 'Meeting Report View',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-meeting-reports-list',
      module: 'meeting',
      resource: 'Tenant',
    },
  },

  // Compose Email View - Meeting List
  {
    path: '/features/meeting/email/compose',
    name: 'feature-meeting-compose-email',
    component: () => import('@/features/meeting/communication/pages/compose/ComposeEmail.vue'),
    props: route => ({ envelopeId: route.query.env }),
    meta: {
      breadcrumb: [
        {
          name: 'Meeting List',
          to: { name: 'feature-meeting-list' },
        },
        {
          name: 'Compose Email',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-meeting-list',
      module: 'meeting',
      resource: 'Tenant',
    },
  },

  // Compose Email View - Email Invitees
  {
    path: '/features/meeting/:meetingId/email/compose',
    name: 'feature-meeting-invitees-compose-email',
    component: () => import('@/features/meeting/communication/pages/compose/ComposeEmail.vue'),
    props: route => ({ envelopeId: route.query.env }),
    meta: {
      breadcrumb: [
        {
          name: 'Meeting List',
          to: { name: 'feature-meeting-list' },
        },
        {
          name: 'Meeting View',
          exact: true,
          to: { name: 'feature-meeting-view' },
          parameterMap: { id: 'meetingId' },
        },
        {
          name: 'Compose Email',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-meeting-list',
      module: 'meeting',
      resource: 'Tenant',
    },
  },

  // Compose Email View - Send Invitations
  {
    path: '/features/meeting/:meetingId/invitations/email/compose',
    name: 'feature-meeting-invitation-compose-email',
    component: () => import('@/features/meeting/communication/pages/compose/ComposeEmailInvitation.vue'),
    props: route => ({ envelopeId: route.query.env }),
    meta: {
      breadcrumb: [
        {
          name: 'Meeting List',
          exact: true,
          to: { name: 'feature-meeting-list' },
        },
        {
          name: 'Meeting View',
          exact: true,
          to: { name: 'feature-meeting-view' },
          parameterMap: { id: 'meetingId' },
        },
        {
          name: 'Invitation List',
          exact: true,
          to: { name: 'feature-meeting-invitation-list' },
        },
        {
          name: 'Compose Email',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-meeting-list',
      module: 'meeting',
      resource: 'Tenant',
    },
  },
]

export default secondary
