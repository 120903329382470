import { mdiCash } from '@mdi/js'

export default [
  {
    title: 'Finance',
    icon: mdiCash,
    resource: 'Tenant',
    children: [
      {
        title: 'Invoice List',
        to: 'feature-finance-invoice-list',
        resource: 'Tenant',
      },
      {
        title: 'Billing Setup',
        to: 'feature-finance-product-list',
        resource: 'Tenant',
      },
      {
        title: 'Finance Reports',
        to: 'feature-finance-reports-list',
        resource: 'Tenant',
      },
    ],
  },
]
