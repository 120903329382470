// log.js
import sendLogToServer from '@/core/logging/sendLog'
import loglevel from 'loglevel'

// Define log levels and their corresponding console styles
const styles = {
  TRACE: 'color: grey; font-size: 12px;',
  DEBUG: 'color: blue; font-size: 12px;',
  INFO: 'color: green; font-size: 12px;',
  WARN: 'color: orange; font-size: 12px;',
  ERROR: 'color: red; font-size: 12px;',
}

// Set the default log level
loglevel.setLevel(process.env.NODE_ENV === 'production' ? 'warn' : 'debug')

// Wrapping loglevel methods to include styling and server logging
const log = {
  log: (message, ...rest) => {
    const formattedMessage = `[LOG] ${message}`
    loglevel.log(`%c${formattedMessage}`, styles.DEBUG, ...rest)
    if (process.env.NODE_ENV !== 'development') {
      sendLogToServer('LOG', `${formattedMessage} ${rest.join(' ')}`)
    }
  },

  trace: (message, ...rest) => {
    const formattedMessage = `[TRACE] ${message}`
    loglevel.trace(`%c${formattedMessage}`, styles.TRACE, ...rest)
    if (process.env.NODE_ENV !== 'development') {
      sendLogToServer('TRACE', `${formattedMessage} ${rest.join(' ')}`)
    }
  },
  debug: (message, ...rest) => {
    const formattedMessage = `[DEBUG] ${message}`
    loglevel.debug(`%c${formattedMessage}`, styles.DEBUG, ...rest)
    if (process.env.NODE_ENV !== 'development') {
      sendLogToServer('DEBUG', `${formattedMessage} ${rest.join(' ')}`)
    }
  },
  info: (message, ...rest) => {
    const formattedMessage = `[INFO] ${message}`
    loglevel.info(`%c${formattedMessage}`, styles.INFO, ...rest)
    if (process.env.NODE_ENV !== 'development') {
      sendLogToServer('INFO', `${formattedMessage} ${rest.join(' ')}`)
    }
  },
  warn: (message, ...rest) => {
    const formattedMessage = `[WARN] ${message}`
    loglevel.warn(`%c${formattedMessage}`, styles.WARN, ...rest)
    if (process.env.NODE_ENV !== 'development') {
      sendLogToServer('WARN', `${formattedMessage} ${rest.join(' ')}`)
    }
  },
  error: (message, ...rest) => {
    const formattedMessage = `[ERROR] ${message}`
    loglevel.error(`%c${formattedMessage}`, styles.ERROR, ...rest)
    if (process.env.NODE_ENV !== 'development') {
      sendLogToServer('ERROR', `${formattedMessage} ${rest.join(' ')}`)
    }
  },
}

export default log
