import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_ANNOUNCEMENT}/referencedata`,
      payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_ANNOUNCEMENT}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters)
  },

  // =========================================
  // feature
  // =========================================
  createHostAnnouncement(ctx, payload) {
    return apiService.post(`${process.env.VUE_APP_API_PATH_ANNOUNCEMENT}/announcements`,
      payload.queryParameters,
      payload.dto)
  },

  updateHostAnnouncement(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_ANNOUNCEMENT}/announcements/${payload.routeParameters.id}`,
      payload.queryParameters,
      payload.dto)
  },

  deleteHostAnnouncement(ctx, payload) {
    return apiService.delete(`${process.env.VUE_APP_API_PATH_ANNOUNCEMENT}/announcements/${payload.routeParameters.id}`,
      payload.queryParameters)
  },

  fetchHostAnnouncement(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_ANNOUNCEMENT}/announcements/${payload.routeParameters.id}`,
      payload.queryParameters)
  },

  fetchHostAnnouncements(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_ANNOUNCEMENT}/announcements`,
      payload.queryParameters)
  },
}
