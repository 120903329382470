<script>
import {
  computed, ref, watch,
} from '@vue/composition-api'
import {
  mdiCloudUploadOutline, mdiDeleteOutline,
  mdiMagnifyMinusOutline, mdiMagnifyPlusOutline, mdiRotateLeft, mdiRotateRight,
} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { Cropper } from 'vue-advanced-cropper'
import { blobToBase64String, resolveImage } from '@/core/utilities'
import 'vue-advanced-cropper/dist/style.css'
import PictureTaker from '@/core/components/picture/PictureTaker.vue'

export default {
  name: 'ContactPictureEdit',
  components: {
    Cropper,
    PictureTaker,
  },
  emit: ['picture-updated'],
  props: {
    inputModel: {
      type: Object,
      required: true,
    },
    allowTakePhoto: {
      type: Boolean,
      required: false,
      default: true,
    },
    config: {
      type: Object,
      required: false,
      default() {
        return {
          mode: 'edit', // add or edit
          saveText: 'Save Changes',
          cancelText: 'Cancel',
          previousText: 'Previous',
          nextText: 'Next',
        }
      },
    },
  },
  setup(props, context) {
    // =========================================
    // variables
    // =========================================
    const fileInput = ref(null)
    const cropper = ref(null)
    const localProfilePicture = ref(null)
    const croppedPictureDataUrl = ref(null)

    // =========================================
    // user session context
    // =========================================

    // =========================================
    // form
    // =========================================

    // =========================================
    // properties
    // =========================================
    const localInputModel = computed(() => JSON.parse(JSON.stringify(props.inputModel)))

    watch(
      () => localInputModel.value,
      async (newValue, oldValue) => {
        croppedPictureDataUrl.value = null
        if (newValue.profilePictureUrl.startsWith('data')) {
          localProfilePicture.value = newValue.profilePictureUrl
        } else {
          localProfilePicture.value = resolveImage(newValue.profilePictureUrl, 240)
        }
      },
      { deep: true, immediate: true },
    )

    const isAddNew = computed(() => props.config.mode === 'add')

    // =========================================
    // reference data
    // =========================================

    // =========================================
    // feature
    // =========================================
    const notifyModelChange = async newModel => {
      const notificationObj = {
        originalPicture: JSON.parse(JSON.stringify(localProfilePicture.value)),
        croppedPicture: JSON.parse(JSON.stringify(newModel)),
      }
      context.emit('picture-updated', notificationObj)
    }

    // watch(
    //   () => croppedPictureDataUrl.value,
    //   async (newValue, oldValue) => {
    //     notifyModelChange(newValue)
    //   },
    // )

    // =========================================
    // use cases
    // =========================================
    // ====================
    // upload photo
    // ====================
    const handleFileUpload = e => {
      if (e.target.files.length === 0) {
        return
      }

      blobToBase64String(
        e.target.files[0],
        result => {
          localProfilePicture.value = result

          // notifyModelChange(croppedPictureDataUrl.value)
        },
        error => {
          console.log('Error occurred while converting blob to base64 string')

          // handle the error...
        },
      )
    }

    // ====================
    // take photo
    // ====================
    const handlePictureTaken = data => {
      localProfilePicture.value = data

      // notifyModelChange(croppedPictureDataUrl.value)
    }

    // ====================
    // take photo
    // ====================
    const deletePhoto = () => {
      localProfilePicture.value = null
      croppedPictureDataUrl.value = null

      if (fileInput.value) {
        fileInput.value.value = ''
      }

      notifyModelChange(croppedPictureDataUrl.value)
    }

    // ====================
    // resize
    // ====================
    const zoomIn = () => {
      cropper.value.zoom(1.5)
    }
    const zoomOut = () => {
      cropper.value.zoom(0.8)
    }
    const rotateLeft = () => {
      cropper.value.rotate(-90)
    }
    const rotateRight = () => {
      cropper.value.rotate(90)
    }

    const handleCropperChange = ({ canvas }) => {
      if (!canvas) return

      if (canvas.height === 0 || canvas.width === 0) {
        croppedPictureDataUrl.value = null

        return
      }

      if (canvas.toDataURL() === null) {
        return
      }
      croppedPictureDataUrl.value = canvas.toDataURL()

      notifyModelChange(croppedPictureDataUrl.value)
    }

    // =========================================
    // ui
    // =========================================
    const previous = () => {
      context.emit('previous-step')
    }

    const next = () => {
      context.emit('next-step')
    }

    // =========================================
    // use cases
    // =========================================

    // =========================================
    // lifecycle
    // =========================================

    // =========================================
    // return
    // =========================================
    return {
      // variables
      localProfilePicture,

      // properties
      localInputModel,

      // reference data

      // form

      // feature

      // ui
      resolveImage,
      avatarText,
      isAddNew,
      previous,
      next,

      // use cases
      croppedPictureDataUrl,
      cropper,
      fileInput,
      PictureTaker,
      handleFileUpload,
      handlePictureTaken,
      handleCropperChange,
      deletePhoto,
      zoomIn,
      zoomOut,
      rotateLeft,
      rotateRight,

      // standard
      validators: { },
      icons: {
        mdiCloudUploadOutline,
        mdiDeleteOutline,
        mdiMagnifyPlusOutline,
        mdiMagnifyMinusOutline,
        mdiRotateLeft,
        mdiRotateRight,
      },
    }
  },
}
</script>

<template>
  <div id="contact-picture-edit">
    <v-row dense>
      <!-- picture column -->
      <v-col
        cols="6"
        class="d-flex justify-end mr-2"
      >
        <!-- no picture/initials -->
        <div v-if="!localProfilePicture">
          <!-- <div>No Picture</div> -->
          <v-avatar
            :color="localProfilePicture ? '' : 'primary'"
            :class="localProfilePicture ? '' : 'v-avatar-light-bg primary--text'"
            size="240"
            rounded
          >
            <span class="font-weight-semibold text-5xl">{{
              avatarText(localInputModel.firstLast)
            }}</span>
          </v-avatar>
        </div>
        <div v-else>
          <!-- <div>Has Picture</div> -->
          <!-- edit mode - image cropper and controls -->
          <div
            style="height: 210px; max-height: 210px; width: 240px;  max-width: 240px;"
          >
            <!-- image cropper -->
            <cropper
              ref="cropper"
              class="cropper"
              :src="localProfilePicture"
              :stencil-props="{
                handlers: {},
                movable: false,
                scalable: false,
              }"
              :stencil-size="{
                width: 200,
                height: 200,

              }"
              image-restriction="stencil"
              @change="handleCropperChange"
            />
          </div>
          <!-- image cropper controls -->
          <div
            style="height: 30px; max-height: 30px; width: 240px; max-width: 240px;"
            class="text-center mt-2"
          >
            <v-btn
              x-small
              @click="zoomIn"
            >
              <v-icon>
                {{ icons.mdiMagnifyPlusOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              x-small
              @click="zoomOut"
            >
              <v-icon>
                {{ icons.mdiMagnifyMinusOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              x-small
              @click="rotateLeft"
            >
              <v-icon>
                {{ icons.mdiRotateLeft }}
              </v-icon>
            </v-btn>
            <v-btn
              x-small
              @click="rotateRight"
            >
              <v-icon>
                {{ icons.mdiRotateRight }}
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>

      <!-- button column -->
      <v-col
        cols="6"
        md="1"
        class="d-flex justify-start"
      >
        <v-row dense>
          <v-col cols="12">
            <v-btn
              color="primary"
              block
              @click="$refs.fileInput.click()"
            >
              <v-icon class="me-1">
                {{ icons.mdiCloudUploadOutline }}
              </v-icon>
              <span>Upload Photo</span>
            </v-btn>
            <div class="mb-3 text-xs text-center">
              Formats allowed: jpg, gif or png.<br />
              Max size: 2MB
            </div>
            <input
              ref="fileInput"
              type="file"
              accept="image/jpeg, image/png, image/bmp, image/gif"
              :hidden="true"
              @change="handleFileUpload"
            />
            <PictureTaker
              v-if="allowTakePhoto"
              @picture-taken="handlePictureTaken"
            />
            <v-btn
              class="my-3"
              color="primary"
              block
              @click="deletePhoto"
            >
              <v-icon class="me-1">
                {{ icons.mdiDeleteOutline }}
              </v-icon>
              <span>Delete Photo</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="isAddNew">
      <v-col cols="12">
        <div>
          <div class="d-flex justify-space-between">
            <v-btn
              v-show="config.previousText !== ''"
              color="secondary"
              outlined
              @click="previous"
            >
              {{ config.previousText }}
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              type="submit"
              @click="next"
            >
              {{ config.nextText }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- <pre>localProfilePicture: {{ localProfilePicture }}</pre> -->
    <!-- <pre>croppedPictureDataUrl: {{ croppedPictureDataUrl }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
// div {
//   border: 1px solid black;
// }
</style>
