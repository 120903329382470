import {
  mdiAccountGroupOutline,
} from '@mdi/js'

export default [
  {
    title: 'Teams',
    icon: mdiAccountGroupOutline,
    resource: 'Tenant',
    children: [
      {
        title: 'Team List',
        to: 'feature-team-list',
        resource: 'Tenant',
      },
      {
        title: 'Team Reports',
        to: 'feature-team-reports-list',
        resource: 'Tenant',
      },
    ],
  },
]
