import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_TEAM}/referencedata`,
      payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters)
  },

  // =========================================
  // feature
  // =========================================

  // =========================================
  // teams
  // =========================================
  fetchTeams(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/teams`,
      payload.queryParameters)
  },

  // =========================================
  // team
  // =========================================
  createTeam(ctx, payload) {
    return apiService.post(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/teams`,
      payload.queryParameters,
      payload.dto)
  },

  updateTeam(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/teams/${payload.routeParameters.teamId}`,
      payload.queryParameters,
      payload.dto)
  },

  archiveTeam(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/teams/${payload.routeParameters.teamId}`,
      payload.queryParameters,
      payload.dto)
  },

  unarchiveTeam(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/teams/${payload.routeParameters.teamId}`,
      payload.queryParameters,
      payload.dto)
  },

  fetchTeam(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/teams/${payload.routeParameters.teamId}`,
      payload.queryParameters)
  },

  // =========================================
  // team member
  // =========================================
  addTeamMemberRole(ctx, payload) {
    return apiService.post(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/teams/${payload.routeParameters.teamId}/members`,
      payload.queryParameters,
      payload.dto)
  },

  updateTeamMemberRole(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/teams/${payload.routeParameters.teamId}/members/${payload.routeParameters.memberId}/teamroles/${payload.routeParameters.teamMemberId}`,
      payload.queryParameters,
      payload.dto)
  },

  deleteTeamMemberRole(ctx, payload) {
    return apiService.delete(`${process.env.VUE_APP_API_PATH_TEAM}/organizations/${payload.routeParameters.organizationId}/teams/${payload.routeParameters.teamId}/members/${payload.routeParameters.memberId}/teamroles/${payload.routeParameters.teamMemberRoleId}`,
      payload.queryParameters,
      payload.dto)
  },
}
