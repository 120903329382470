import { mdiAccountMultipleOutline } from '@mdi/js'

export default [
  {
    title: 'Membership',
    icon: mdiAccountMultipleOutline,
    resource: 'Tenant',
    children: [
      {
        title: 'Member List',
        to: 'feature-user-list',
        resource: 'Tenant',
      },
      {
        title: 'Member Reports',
        to: 'feature-user-reports-list',
        resource: 'Tenant',
      },
    ],
  },
]
