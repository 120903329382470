import useApiError from '@/core/api/useApiError'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default function useHostReferenceData(feature) {
  // =========================================
  // register module(s)
  // =========================================

  // =========================================
  // variables
  // =========================================
  const featureName = `feature${feature}`
  const mutationName = `${featureName}/SET_HOST_REFERENCE_DATA`
  const getterName = `${featureName}/getHostReferenceData`
  const actionName = `${featureName}/fetchHostReferenceData`

  // =========================================
  // error handling
  // =========================================
  const {
    addError,
  } = useApiError()

  // =========================================
  // user context
  // =========================================

  // =========================================
  // feature
  // =========================================
  const hostReferenceData = computed({
    get: () => store.getters[getterName],
    set: newValue => {
      store.commit(mutationName, newValue)
    },
  })

  // =========================================
  // api
  // =========================================
  const fetchHostReferenceData = async () => {
    try {
      if (hostReferenceData.value && Object.keys(hostReferenceData.value).length > 0) {
        return true
      }

      const response = await store.dispatch(actionName, {
        routeParameters: {},
        queryParameters: {},
      })

      if (response.status === 200) {
        hostReferenceData.value = JSON.parse(JSON.stringify(response.data))

        return true
      }
      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  // =========================================
  // return
  // =========================================
  return {
    hostReferenceData,

    fetchHostReferenceData,
  }
}
