import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_COMMITTEE}/referencedata`,
      payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters)
  },

  // =========================================
  // feature
  // =========================================

  // =========================================
  // committee settings
  // =========================================
  fetchCommitteeSettings(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees/settings`,
      payload.queryParameters)
  },

  saveCommitteeTermSettings(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees/settings/terms`,
      payload.queryParameters,
      payload.dto)
  },

  // =========================================
  // committees
  // =========================================
  fetchCommittees(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees`,
      payload.queryParameters)
  },

  // =========================================
  // committee
  // =========================================
  createCommittee(ctx, payload) {
    return apiService.post(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees`,
      payload.queryParameters,
      payload.dto)
  },

  updateCommittee(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees/${payload.routeParameters.committeeId}`,
      payload.queryParameters,
      payload.dto)
  },

  archiveCommittee(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees/${payload.routeParameters.committeeId}`,
      payload.queryParameters,
      payload.dto)
  },

  unarchiveCommittee(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees/${payload.routeParameters.committeeId}`,
      payload.queryParameters,
      payload.dto)
  },

  fetchCommittee(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees/${payload.routeParameters.committeeId}`,
      payload.queryParameters)
  },

  // =========================================
  // committee members
  // =========================================
  addCommitteeMemberRole(ctx, payload) {
    return apiService.post(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees/${payload.routeParameters.committeeId}/members`,
      payload.queryParameters,
      payload.dto)
  },

  updateCommitteeMemberRole(ctx, payload) {
    return apiService.put(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees/${payload.routeParameters.committeeId}/members/${payload.routeParameters.memberId}/committeeroles/${payload.routeParameters.committeeMemberId}`,
      payload.queryParameters,
      payload.dto)
  },

  deleteCommitteeMemberRole(ctx, payload) {
    return apiService.delete(`${process.env.VUE_APP_API_PATH_COMMITTEE}/organizations/${payload.routeParameters.organizationId}/committees/${payload.routeParameters.committeeId}/members/${payload.routeParameters.memberId}/committeeroles/${payload.routeParameters.committeeMemberId}`,
      payload.queryParameters,
      payload.dto)
  },
}
