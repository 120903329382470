/* eslint-disable prefer-destructuring */
export default {
  SET_HOST_REFERENCE_DATA(state, val) {
    state.hostReferenceData = val
  },
  SET_TENANT_REFERENCE_DATA(state, val) {
    state.tenantReferenceData = val
  },
  SET_USER_CONTEXT(state, val) {
    state.userSessionContext.userContext = val
  },
  SET_ORGANIAZTON_CONTEXT(state, val) {
    const organizationContext = {
      organizationId: val.organizationId,
      organizationName: val.organizationName,
      userRole: val.userRole,
      userRoleDisplayName: val.userRoleDisplayName,
      abilities: val.abilities,
    }
    state.userSessionContext.organizationContext = organizationContext
  },
}
