import useApiError from '@/core/api/useApiError'
import constants from '@/features/user2/_framework/constants'
import { initialAbility } from '@/plugins/acl/config'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default function useUser2SessionContext() {
  // =========================================
  // register module(s)
  // =========================================

  // =========================================
  // error handling
  // =========================================
  const {
    addError,
  } = useApiError()

  // =========================================
  // user context
  // =========================================
  const userSessionContext = computed(() => store.state.featureUser2.userSessionContext)

  const userContext = computed({
    get: () => store.state.featureUser2.userSessionContext.userContext,
    set: newValue => {
      store.commit('featureUser2/SET_USER_CONTEXT', newValue)
    },
  })

  const isUserContextLoaded = computed(() => Object.keys(userContext.value).length > 0)

  const organizationContext = computed({
    get: () => store.state.featureUser2.userSessionContext.organizationContext,
    set: val => {
      setActiveOrganizationContext(val.organizationAlternateId)
      store.commit('featureUser2/SET_ORGANIAZTON_CONTEXT', val)
    },
  })
  const userOrganizationContextRolesDisplay = computed(() => store.state.featureUser2.userSessionContext.organizationContext.userRoleDisplayName)

  // =========================================
  // api
  // =========================================
  const setActiveOrganizationContext = async organizationAlternateId => {
    if (organizationAlternateId) {
      localStorage.setItem('imsActiveOrganizationAlternateId', organizationAlternateId)
    }
  }

  const removeActiveOrganizationContext = () => {
    localStorage.removeItem('imsActiveOrganizationAlternateId')
  }

  const fetchUserSessionContext = async () => {
    try {
      const response = await store
        .dispatch(`${constants.USER2_CONTEXT_STORE_MODULE_NAME}/fetchUserSessionContext`,
          {
            routeParameters: { },
            queryParameters: { },
          })

      if (response.status === 200) {
        userContext.value = JSON.parse(JSON.stringify(response.data))

        const { organizationContexts } = response.data
        switch (organizationContexts.length) {
          case 0:
            break
          case 1:
          {
            const selectedOrgContext = organizationContexts[0]

            if (selectedOrgContext.isAccessEnabled) {
              organizationContext.value = selectedOrgContext
            } else {
              // Handle the case where the only organization context is not enabled.
              // This could be setting a default, showing an error, etc.
            }

            break
          }

          default:
          {
            const activeOrganizationAlternateId = localStorage.getItem('imsActiveOrganizationAlternateId')

            let selectedOrgContext = null

            // Check for active organization context from localStorage
            if (activeOrganizationAlternateId) {
              selectedOrgContext = organizationContexts.find(x => x.isAccessEnabled === true && x.organizationAlternateId === activeOrganizationAlternateId)
            }

            // If not found, check for default enabled organization context
            if (!selectedOrgContext) {
              selectedOrgContext = organizationContexts.find(x => x.isAccessEnabled === true && x.isDefault)
            }

            // If still not found, get the first enabled organization context
            if (!selectedOrgContext) {
              selectedOrgContext = organizationContexts.find(x => x.isAccessEnabled === true)
            }

            // Set the organization context or handle if none found
            if (selectedOrgContext) {
              organizationContext.value = selectedOrgContext
            } else {
              // Handle error or set a default
            }
          }
        }

        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      const userContextVm = {
        userId: '',
        contactId: '',
        firstName: '',
        lastName: '',
        get firstLast() {
          return `${this.firstName} ${this.lastName}`
        },
        get lastFirst() {
          return `${this.lastName} ${this.firstName}`
        },
        profilePictureUrl: '',
        isAuthenticated: false,
        organizationContexts: [
          {
            organizationId: '',
            organizationName: '',
            abilities: initialAbility,
          },
        ],
      }

      userContext.value = JSON.parse(JSON.stringify(userContextVm))

      const [firstOrganizationContext] = userContextVm.organizationContexts
      organizationContext.value = firstOrganizationContext

      return true
    }
  }

  // =========================================
  // return
  // =========================================
  return {
    userSessionContext,
    userContext,
    isUserContextLoaded,
    organizationContext,
    userOrganizationContextRolesDisplay,

    setActiveOrganizationContext,
    removeActiveOrganizationContext,
    fetchUserSessionContext,
  }
}
