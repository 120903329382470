<script>
import {
  computed, ref, watch, onMounted, onUnmounted,
} from '@vue/composition-api'
import SignOutControl from '@/core/components/signout/SignOutControl.vue'

export default {
  name: 'IdleDialog',
  components: {
    SignOutControl,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const startTime = ref(null)
    const timespan = 30000 // Initialize with 30 seconds
    const remainingTime = ref(timespan)
    let timerInterval = null

    const calculateRemainingTime = () => {
      if (startTime.value) {
        const now = new Date().getTime()
        const elapsed = now - startTime.value
        remainingTime.value = Math.max(timespan - elapsed, 0)
      }
    }

    const startTimer = () => {
      startTime.value = new Date().getTime()
      calculateRemainingTime()
      timerInterval = setInterval(() => {
        calculateRemainingTime()
      }, 1000)
    }

    const stopTimer = () => {
      clearInterval(timerInterval)
      timerInterval = null
      startTime.value = null
    }

    const handleVisibilityChange = () => {
      calculateRemainingTime()
    }

    onMounted(() => {
      document.addEventListener('visibilitychange', handleVisibilityChange)
    })

    onUnmounted(() => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      stopTimer()
    })

    const secondsRemaining = computed(() => Math.floor(remainingTime.value / 1000))

    watch(
      () => props.showDialog,
      newValue => {
        if (newValue) {
          startTimer()
        } else {
          stopTimer()
        }
      },
    )

    const closeDialog = () => {
      context.emit('update:showDialog', false)
      stopTimer()
    }

    const continueSession = () => {
      closeDialog()
    }

    return {
      secondsRemaining,
      closeDialog,
      continueSession,
    }
  },
}
</script>

<template>
  <v-dialog
    v-model="showDialog"
    max-width="650px"
    persistent
  >
    <v-card>
      <v-card-title class="justify-center text-h5">
        Still there?
      </v-card-title>
      <v-card-subtitle class="d-flex justify-center mt-3">
        Your session will expire soon due to inactivity
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <span v-if="secondsRemaining !== 0">Time remaining 0:{{ secondsRemaining }}</span>
            <span v-else>Time remaining 0:00</span>
          </v-col>
        </v-row>

        <v-card-actions class="d-flex justify-center mt-3">
          <v-btn
            color="primary"
            class="me-3"
            @click="continueSession"
          >
            Continue Session
          </v-btn>
          <SignOutControl
            :as-icon="false"
            :session-expired="secondsRemaining <= 0"
          />
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
