import ApiError from '@/core/api/models/ApiError'
import ProblemDetail from '@/core/api/models/ProblemDetail'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default function useApiError() {
  // An error can be one of three types:

  // 1. (Server Validation Error) A handled validation error returned by the server
  //    - The server will return a ValidationProblemDetail (status 400)
  //    - Axios will wrap the problem detail error due to the non-200 status code
  //    - Axios will expose the error via
  //      - error.response.data (ValidationProblemDetail)
  //          - detail
  //          - errors
  //          - instance
  //          - status
  //          - title
  //          - type
  //      - error.message
  //      - error.stack

  // 2. (Server Error) An unhanded exception thrown by the server
  //    - The server will return a ProblemDetail
  //    - Axios will wrap the problem detail error due to the non-200 status code
  //    - Axios will expose the error via
  //      - error.response.data (ProblemDetail)
  //          - detail
  //          - instance
  //          - status
  //          - title
  //      - error.message
  //      - error.stack

  // 3. (Client Error) An http error where axios failed to call the server.
  //    - error.data will be empty string
  //    - Axios will expose the error via
  //      - error.response.request.status
  //      - error.response.request.statusText
  //      - error.message
  //      - error.stack

  // =========================================
  // variables
  // =========================================
  const featureName = 'appErrors'
  const mutationName = `${featureName}/SET_ERROR_ALERTS`
  const getterName = `${featureName}/getErrorAlerts`

  // const actionName = `${featureName}/fetchErrorAlerts`

  // =========================================
  // feature
  // =========================================
  const errorAlerts = computed({
    get: () => store.getters[getterName],
    set: newValue => {
      store.commit(mutationName, newValue)
    },
  })

  const addError = axiosError => {
    // if Server error -
    //  - ValidationProblemDetail (error.response.data)
    //  - ProblemDetail (error.response.data)
    // if Client error - http error
    if (axiosError.response) {
      const problemDetail = axiosError.response.data
        ? new ProblemDetail(
          axiosError.response.data.instance,
          axiosError.response.data.status,
          axiosError.response.data.title,
          axiosError.response.data.detail,
        )
        : new ProblemDetail(
          null,
          axiosError.response.status,
          axiosError.response.statusText,
          axiosError.message,
        )

      const errors = axiosError.response.data?.errors ?? {}

      errorAlerts.value = [...errorAlerts.value, new ApiError(problemDetail, errors, 'ServerError')]
    } else {
      const problemDetail = new ProblemDetail(
        null,
        null,
        axiosError.message,
        axiosError.stack,
        '',
      )

      const errors = [...errorAlerts.value, new ApiError(problemDetail, null, 'ClientError')]

      errorAlerts.value = errors
    }
  }

  const clearErrors = () => {
    errorAlerts.value.splice(0)
  }

  // =========================================
  // return
  // =========================================
  return {
    errorAlerts,
    addError,
    clearErrors,
  }
}
