const primary = [
  {
    path: '/features/user/list',
    name: 'feature-user-list',
    component: () => import('@/features/user/domain/pages/list/ContactListPage.vue'),
    meta: {
      layout: 'content',
      module: 'user',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/user/reports/list',
    name: 'feature-user-reports-list',
    component: () => import('@/features/user/reports/report-list/ReportList.vue'),
    meta: {
      layout: 'content',
      module: 'user',
      resource: 'Tenant',
    },
  },
]

export default primary
