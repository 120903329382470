import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_MEETING}/referencedata`, payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters,
    )
  },

  // =========================================
  // Meeting List
  // =========================================
  fetchMeetings(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings`,
      payload.queryParameters,
    )
  },

  // =========================================
  // Meeting
  // =========================================
  createMeeting(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // =========================================
  // Duplicate Meeting
  // =========================================
  duplicateMeeting(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/duplicateMeeting`,
      payload.queryParameters,
      payload.dto,
    )
  },

  updateMeeting(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.id}`,
      payload.queryParameters,
      payload.dto,
    )
  },

  archiveMeeting(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.id}/archive`,
      payload.queryParameters,
      payload.dto,
    )
  },

  restoreMeeting(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.id}/restore`,
      payload.queryParameters,
      payload.dto,
    )
  },

  deleteMeeting(ctx, payload) {
    return apiService.delete(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.id}`,
      payload.queryParameters,
    )
  },

  fetchMeeting(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.id}`,
      payload.queryParameters,
    )
  },

  fetchInvitationRsvpStatusSummary(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/charts/invitation-status-summary`,
      payload.queryParameters,
    )
  },

  fetchMealChoiceSummary(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/charts/meal-choice-summary`,
      payload.queryParameters,
    )
  },

  fetchSubstituteAndGuestSummary(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/charts/substitute-and-guest-summary`,
      payload.queryParameters,
    )
  },

  createInvitations(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/invitations`,
      payload.queryParameters,
      payload.dto,
    )
  },

  fetchCommunicationGroups(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/communication-groups`,
      payload.queryParameters,
    )
  },

  fetchInvitations(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/invitations`,
      payload.queryParameters,
    )
  },

  fetchMembers(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/members`,
      payload.queryParameters,
    )
  },

  fetchRsvpResponse(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/invitations/${payload.routeParameters.invitationId}/rsvpresponse`,
      payload.queryParameters,
    )
  },

  updateRsvpResponse(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/invitations/${payload.routeParameters.invitationId}/rsvpresponse`,
      payload.queryParameters,
      payload.dto,
    )
  },

  createGuest(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/invitations/${payload.routeParameters.invitationId}/guests`,
      payload.queryParameters,
      payload.dto,
    )
  },

  createRegistration(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/registrations`,
      payload.queryParameters,
      payload.dto,
    )
  },

  updateRegistrationAttendance(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_MEETING}/organizations/${payload.routeParameters.organizationId}/meetings/${payload.routeParameters.meetingId}/registrations/${payload.routeParameters.registrationId}`,
      payload.queryParameters,
      payload.dto,
    )
  },
}
