const primary = [
  {
    path: '/administration/announcement/list',
    name: 'administration-announcement-list',
    component: () => import('@/features/announcement/settings/host/pages/list/List.vue'),
    meta: {
      layout: 'content',
      module: 'announcement',
    },
  },
]

export default primary
