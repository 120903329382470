/* eslint-disable prefer-destructuring */
export default {
  SET_HOST_REFERENCE_DATA(state, val) {
    state.hostReferenceData = val
  },
  SET_TENANT_REFERENCE_DATA(state, val) {
    state.tenantReferenceData = val
  },
  SET_INVOICE_SETTINGS(state, val) {
    state.invoiceSettings = val
  },
}
