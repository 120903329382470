<script>
import { computed, ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'

export default {
  name: 'OrganizationContextSwitcher',
  components: { },
  setup() {
  // =========================================
  // user session context
  // =========================================
    const {
      organizationContext,
      userContext,
    } = useUser2SessionContext()

    const { router } = useRouter()

    const organizationContextFilter = computed(() => userContext.value.organizationContexts.filter(o => o.userRole.toLowerCase() !== 'tenantuser'))

    const onContextChange = () => {
      router.push({
        name: 'dashboard-default',
        query: { v: '2' },
      }).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated'
      && !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          console.log(err)
        }
      })
    }

    // =========================================
    // organization list table
    // =========================================
    const searchOrganizations = ref('')
    const columnHeaders = ref([
      { text: 'NAME', value: 'organizationName', width: '100%' },
    ])

    return {
      userContext,
      organizationContext,
      organizationContextFilter,
      onContextChange,

      searchOrganizations,
      columnHeaders,

      validators: { required },
    }
  },
}
</script>

<template>
  <div>
    <app-autocomplete
      v-model="organizationContext"
      :rules="[validators.required]"
      :items="organizationContextFilter"
      item-text="organizationName"
      item-value="organizationId"
      class="mb-4"
      return-object
      auto-select-first
      @change="onContextChange"
    />
  </div>
</template>

<style lang="scss">

</style>
