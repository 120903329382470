import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  userSessionContext: {
    hostReferenceData: {},
    tenantReferenceData: {},
    userContext: {},
    organizationContext: {},
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
