const secondary = [
  {
    path: '/administration/announcement/view/:id?',
    name: 'administration-announcement-view',
    component: () => import('@/features/announcement/settings/host/pages/view/View.vue'),
    meta: {
      layout: 'content',
      module: 'announcement',
      resource: 'Tenant',
      navActiveLink: 'administration-announcement-list',
      breadcrumb: [
        {
          name: 'Announcement List',
          exact: true,
          to: { name: 'administration-announcement-list', path: 'announcement/admin/list' },
        }, {

          name: 'Announcement View',
        },
      ],
    },
  },
]

export default secondary
