const primary = [
  {
    path: '/features/report/list',
    name: 'feature-report-list',
    component: () => import('@/features/report/domain/pages/list/ReportList.vue'),
    meta: {
      layout: 'content',
      module: 'report',
      resource: 'Tenant',
    },
  },
]

export default primary
