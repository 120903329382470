import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_LOGGING}/referencedata`,
      payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_LOGGING}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters)
  },

  // =========================================
  // activity list
  // =========================================
  fetchActivityList(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_LOGGING}/organizations/${payload.routeParameters.organizationId}/activities`,
      payload.queryParameters)
  },

  // =========================================
  // activities
  // =========================================

  // =========================================
  // activity
  // =========================================
  fetchAuditLogActivity(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_LOGGING}/organizations/${payload.routeParameters.organizationId}/activities/auditlogs/${payload.routeParameters.auditLogId}`,
      payload.queryParameters)
  },
  fetchEventLogActivity(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_LOGGING}/organizations/${payload.routeParameters.organizationId}/activities/eventlogs/${payload.routeParameters.eventLogId}`,
      payload.queryParameters)
  },
  fetchLogActivity(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_LOGGING}/organizations/${payload.routeParameters.organizationId}/activities/logs/${payload.routeParameters.logId}`,
      payload.queryParameters)
  },

  // =========================================
  // error submission
  // =========================================
  createErrorSubmission(ctx, payload) {
    return apiService.post(`${process.env.VUE_APP_API_PATH_LOGGING}/organizations/${payload.routeParameters.organizationId}/errorsubmissions`,
      payload.queryParameters,
      payload.dto)
  },
}
