const secondary = [
  {
    path: '/features/report/view/:id',
    name: 'feature-report-view',
    component: () => import('@/features/report/domain/pages/view/ReportView.vue'),
    meta: {
      breadcrumb: [{
        name: 'Report List',
        exact: true,
        to: { name: 'feature-report-list' },
      }, {

        name: 'Report View',
      }],
      layout: 'content',
      navActiveLink: 'feature-report-list',
      module: 'report',
      resource: 'Tenant',
    },
  },
]

export default secondary
