import useAppEnvironmentContext from '@/core/components/environment/useAppEnvironmentContext'
import {
  mdiContentDuplicate, mdiFormTextbox, mdiHexagonMultipleOutline, mdiSeesaw,
} from '@mdi/js'

const {
  isDevelopment,
} = useAppEnvironmentContext()

const menu = (1 === 2)
  ? [
    {
      subheader: 'DEVELOPMENT',
    },
    {
      title: 'Process Manager',
      icon: mdiHexagonMultipleOutline,
      to: 'development-process-manager',
    },
    {
      title: 'Playground',
      icon: mdiSeesaw,
      children: [
        {
          title: 'Default',
          to: 'development-playground-default',
        },
        {
          title: 'Blank Layout',
          to: 'development-playground-blanklayout',
        },
        {
          title: 'Donation Import List',
          to: 'development-playground-donation-import-instance-list',
        },
        {
          title: 'Donation Import Instance',
          to: 'development-playground-donation-import-instance-view',
        },
      ],
    },
    {
      title: 'Controls',
      icon: mdiFormTextbox,
      children: [
        {
          title: 'Default',
          to: 'development-controls-control-default',
        },
        {
          title: 'Date Control',
          to: 'development-controls-date-control-demo',
        },
      ],
    },
    {
      title: 'Templates',
      icon: mdiContentDuplicate,
      children: [
        {
          title: 'Use Composition',
          to: 'development-templates-use-composition',
        },
        {
          title: 'Store Module',
          to: 'development-templates-store-module',
        },
        {
          title: 'Panel',
          to: 'development-templates-panel',
        },
        {
          title: 'Component',
          to: 'development-templates-component',
        },
        {
          title: 'Component with Form',
          to: 'development-templates-component-with-form',
        },
        {
          title: 'Dialog Full Page Stepper with Form',
          to: 'development-templates-dialog-full-page-stepper-with-form',
        },
        {
          title: 'Dialog Basic',
          to: 'development-templates-dialog-basic',
        },
        {
          title: 'Stepper with Components',
          to: 'development-templates-stepper-with-components',
        },
        {
          title: 'Stepper with Forms',
          to: 'development-templates-stepper-with-forms',
        },
      ],
    },
  ]
  : []

export default menu
