const development = [
  // Development - Process Manager
  {
    path: '/development/process-manager/default',
    name: 'development-process-manager',
    component: () => import('@/features/process-manager/settings/host/Default.vue'),
    meta: {
      layout: 'content',
      module: 'development',
    },
  },

  // Development - Playground
  {
    path: '/development/playground/default',
    name: 'development-playground-default',
    component: () => import('@/views/development/playground/default/Default.vue'),
    meta: {
      layout: 'content',
      module: 'development',
    },
  },
  {
    path: '/development/playground/blanklayout',
    name: 'development-playground-blanklayout',
    component: () => import('@/views/development/playground/blank-layout/Default.vue'),
    meta: {
      layout: 'blank',
      module: 'development',
    },
  },
  {
    path: '/development/playground/donation-import/instance-list',
    name: 'development-playground-donation-import-instance-list',
    component: () => import('@/views/development/playground/donation-import/instance-list/InstanceList.vue'),
    meta: {
      layout: 'content',
      module: 'development',
    },
  },
  {
    path: '/development/playground/donation-import/instance-view',
    name: 'development-playground-donation-import-instance-view',
    component: () => import('@/views/development/playground/donation-import/instance-view/InstanceView.vue'),
    meta: {
      layout: 'content',
      module: 'development',
    },
  },
]

export default development
