<script>
import { avatarText } from '@core/utils/filter'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'
import { base64StringToBlob, resolveImage } from '@/core/utilities'
import ContactPictureEditDialog from '@/features/user/domain/components/dialogs/ContactPictureEditDialog.vue'
import useContact from '@/features/user/_framework/composables/useContact'

export default {
  name: 'UserDisplay',
  components: {
    ContactPictureEditDialog,
  },
  props: {
    showNameAndRole: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowAvatarEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
  // =========================================
  // user session context
  // =========================================
    const {
      userContext,
      userOrganizationContextRolesDisplay,
      fetchUserSessionContext,
    } = useUser2SessionContext()

    // =========================================
    // feature
    // =========================================
    const {
      uploadContactProfilePicture,
    } = useContact()

    const save = async apiData => {
      const dataUri = apiData.dto

      const data = {
        contactId: userContext.value.contactId,
        dto: dataUri ? [base64StringToBlob(dataUri)] : [],
        queryParameters: apiData.queryParameters,
      }

      if (await uploadContactProfilePicture(data)) {
        return true
      }

      return false
    }

    const handlePictureSaved = async () => {
      await fetchUserSessionContext()
    }

    // =========================================
    // return
    // =========================================
    return {
      userContext,
      userOrganizationContextRolesDisplay,

      // feature
      save,
      handlePictureSaved,

      // ui
      resolveImage,
      avatarText,

      // standard
      validators: { },
      icons: { },
    }
  },
}
</script>

<template>
  <div id="user-display">
    <div
      v-if="allowAvatarEdit"
      id="edit"
    >
      <v-badge
        avatar
        overlap
      >
        <template #badge>
          <v-avatar
            size="15"
          >
            <ContactPictureEditDialog
              :input-model="userContext"
              :save="save"
              :allow-take-photo="true"
              @picture-saved="handlePictureSaved"
            />
          </v-avatar>
        </template>

        <!-- Avatar Block -->
        <v-avatar
          size="50"
          color="primary"
          class="v-avatar-light-bg primary--text"
        >
          <img
            v-if="userContext.profilePictureUrl !== ''"
            :src="resolveImage(userContext.profilePictureUrl)"
            alt="user avatar"
          >
          <span
            v-else
            class="font-weight-semibold text-5md"
          >{{ avatarText( userContext.firstName.concat(" ", userContext.lastName)) }}
          </span>
        </v-avatar>
      </v-badge>

      <div
        v-if="showNameAndRole"
        class="d-inline-flex flex-column justify-center ms-3"
        style="vertical-align:middle"
      >
        <router-link
          :to="{ name: 'feature-user-view', params: { id: userContext.contactId } }"
          tag="button"
          class="text-left"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userContext.firstName }} {{ userContext.lastName }}
          </span>
        </router-link>

        <small class="text--disabled text-capitalize">Role: {{ userOrganizationContextRolesDisplay }}</small>
      </div>
    </div>

    <div
      v-else
      id="noedit"
    >
      <v-avatar
        size="50"
        color="primary"
        class="v-avatar-light-bg primary--text"
      >
        <img
          v-if="userContext.profilePictureUrl !== ''"
          :src="resolveImage(userContext.profilePictureUrl)"
          alt="user avatar"
        >
        <span
          v-else
          class="font-weight-semibold text-5md"
        >{{ avatarText( userContext.firstName.concat(" ", userContext.lastName)) }}
        </span>
      </v-avatar>

      <div
        v-if="showNameAndRole"
        class="d-inline-flex flex-column justify-center ms-3"
        style="vertical-align:middle"
      >
        <span class="text--primary font-weight-semibold mb-n1">
          {{ userContext.firstName }} {{ userContext.lastName }}
        </span>
        <small class="text--disabled text-capitalize">Role: {{ userOrganizationContextRolesDisplay }}</small>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// div {
//   border: 1px solid black;
// }
</style>
