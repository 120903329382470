import useApiError from '@/core/api/useApiError'
import log from '@/core/logging/log'
import store from '@/store'
import axios from '@axios'

const { clearErrors } = useApiError()

const apiService = {
  // When using the alias methods url, method, and data properties don't need to be specified in config.
  // axios.request(config)
  // axios.get(url[, config])
  // axios.delete(url[, config])
  // axios.head(url[, config])
  // axios.options(url[, config])
  // axios.post(url[, data[, config]])
  // axios.put(url[, data[, config]])
  // axios.patch(url[, data[, config]])

  get(url, queryParameters, showLoading = true) {
    const config = {
      method: 'get',
      url,
      params: queryParameters,
      responseType: 'json', // json, blob, document, arraybuffer, text, stream
    }

    return this.apiRequest(config, showLoading)
  },

  getBlob(url, queryParameters, showLoading = true) {
    const config = {
      method: 'get',
      url,
      params: queryParameters,
      responseType: 'blob',
    }

    return this.apiRequest(config, showLoading)
  },

  getBlobs(url, queryParameters, dto, showLoading = true) {
    const config = {
      method: 'post',
      url,
      params: queryParameters,
      responseType: 'blob',
      data: dto,
    }

    return this.apiRequest(config, showLoading)
  },

  post(url, queryParameters, dto, showLoading = true) {
    const config = {
      method: 'post',
      url,
      params: queryParameters,
      data: dto,
    }

    return this.apiRequest(config, showLoading)
  },

  postBlob(url, queryParameters, dto, showLoading = true) {
    const formData = new FormData()
    dto.forEach(file => {
      formData.append('file', file, file.name)
    })

    const config = {
      method: 'post',
      url,
      params: queryParameters,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    }

    return this.apiRequest(config, showLoading)
  },

  put(url, queryParameters, dto, showLoading = true) {
    const config = {
      method: 'put',
      url,
      params: queryParameters,
      data: dto,
    }

    return this.apiRequest(config, showLoading)
  },

  patch(url, queryParameters, dto, showLoading = true) {
    const config = {
      method: 'patch',
      url,
      params: queryParameters,
      data: dto,
    }

    return this.apiRequest(config, showLoading)
  },

  delete(url, queryParameters, dto, showLoading = true) {
    const config = {
      method: 'delete',
      url,
      params: queryParameters,
      data: dto,
    }

    return this.apiRequest(config, showLoading)
  },

  apiRequest(config, showLoading = true) {
    log.debug('apiService.apiRequest', config)

    clearErrors()

    if (showLoading) {
      store.commit('app/INCREMENT_LOADING')
    }

    return new Promise((resolve, reject) => {
      axios
        .request(config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
        .then(() => {
          // always executed (finally)
          if (showLoading) {
            store.commit('app/DECREMENT_LOADING')
          }
        })
    })
  },
}
export default apiService
