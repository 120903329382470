import log from '@/core/logging/log'
import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'

import useAppEnvironmentContext from '@/core/components/environment/useAppEnvironmentContext'
import activityRoutes from '@/features/activity/_framework/routes/routes'
import announcementRoutes from '@/features/announcement/_framework/routes/routes'
import committeeRoutes from '@/features/committee/_framework/routes/routes'
import communicationRoutes from '@/features/communication/_framework/routes/routes'
import documentRoutes from '@/features/document/_framework/routes/routes'
import financeRoutes from '@/features/finance/_framework/routes/routes'
import meetingRoutes from '@/features/meeting/_framework/routes/routes'
import notificationRoutes from '@/features/notification/_framework/routes/routes'
import reportRoutes from '@/features/report/_framework/routes/routes'
import teamRoutes from '@/features/team/_framework/routes/routes'
import userRoutes from '@/features/user/_framework/routes/routes'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'
import system from '@/views/system/_framework/routes/routes'
import dashboard from './dashboard'
import development from './development'

import useHostReferenceData from '@/core/referenceData/useHostReferenceData'
import useTenantReferenceData from '@/core/referenceData/useTenantReferenceData'

import useApiError from '@/core/api/useApiError'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      log.debug('root path', to)

      return { name: 'dashboard-default' }
    },
  },

  ...system,

  ...dashboard,
  ...userRoutes,
  ...committeeRoutes,
  ...teamRoutes,
  ...meetingRoutes,
  ...financeRoutes,
  ...communicationRoutes,

  ...documentRoutes,
  ...reportRoutes,

  ...activityRoutes,

  ...announcementRoutes,
  ...notificationRoutes,

  ...development,

  {
    path: '*',
    redirect: to => {
      log.debug('404', to)

      return { name: 'error-404' }
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

const moduleReferenceDataHandlers = {
  user: async () => {
    const { fetchHostReferenceData: fetchHostReferenceDataUser } = useHostReferenceData('User')
    const { fetchHostReferenceData: fetchHostReferenceDataCommunication } = useHostReferenceData('Communication')
    const { fetchHostReferenceData: fetchHostReferenceDataReport } = useHostReferenceData('Report')

    await fetchHostReferenceDataUser()
    await fetchHostReferenceDataCommunication()
    await fetchHostReferenceDataReport()
  },
  committee: async () => {
    const { fetchHostReferenceData: fetchHostReferenceDataCommittee } = useHostReferenceData('Committee')
    const { fetchTenantReferenceData: fetchTenantReferenceDataCommittee } = useTenantReferenceData('Committee')
    const { fetchHostReferenceData: fetchHostReferenceDataCommunication } = useHostReferenceData('Communication')
    const { fetchHostReferenceData: fetchHostReferenceDataReport } = useHostReferenceData('Report')

    await fetchHostReferenceDataCommittee()
    await fetchTenantReferenceDataCommittee()
    await fetchHostReferenceDataCommunication()
    await fetchHostReferenceDataReport()
  },
  team: async () => {
    const { fetchHostReferenceData } = useHostReferenceData('Team')
    const { fetchTenantReferenceData } = useTenantReferenceData('Team')
    const { fetchHostReferenceData: fetchHostReferenceDataReport } = useHostReferenceData('Report')
    const { fetchHostReferenceData: fetchHostReferenceDataCommunication } = useHostReferenceData('Communication')

    await fetchHostReferenceDataCommunication()
    await fetchHostReferenceData()
    await fetchTenantReferenceData()
    await fetchHostReferenceDataReport()
  },
  meeting: async () => {
    const { fetchHostReferenceData: fetchHostReferenceDataUser } = useHostReferenceData('User')

    const { fetchHostReferenceData: fetchHostReferenceDataMeeting } = useHostReferenceData('Meeting')
    const { fetchTenantReferenceData: fetchTenantReferenceDataMeeting } = useTenantReferenceData('Meeting')
    const { fetchHostReferenceData: fetchHostReferenceDataCommunication } = useHostReferenceData('Communication')
    const { fetchHostReferenceData: fetchHostReferenceDataReport } = useHostReferenceData('Report')

    await fetchHostReferenceDataUser()
    await fetchHostReferenceDataMeeting()
    await fetchTenantReferenceDataMeeting()
    await fetchHostReferenceDataCommunication()
    await fetchHostReferenceDataReport()
  },
  finance: async () => {
    const { fetchHostReferenceData } = useHostReferenceData('Finance')
    const { fetchHostReferenceData: fetchHostReferenceDataUser } = useHostReferenceData('User')
    const { fetchHostReferenceData: fetchHostReferenceDataReport } = useHostReferenceData('Report')
    const { fetchHostReferenceData: fetchHostReferenceDataCommunication } = useHostReferenceData('Communication')

    await fetchHostReferenceDataCommunication()
    await fetchHostReferenceData()
    await fetchHostReferenceDataUser()
    await fetchHostReferenceDataReport()
  },
  communication: async () => {
    const { fetchHostReferenceData: fetchHostReferenceDataUser } = useHostReferenceData('User')
    const { fetchHostReferenceData } = useHostReferenceData('Communication')

    await fetchHostReferenceDataUser()
    await fetchHostReferenceData()
  },
  document: async () => { },
  report: async () => {
    const { fetchHostReferenceData } = useHostReferenceData('Report')

    await fetchHostReferenceData()
  },
  logging: async () => { },
  announcement: async () => {
    const { fetchHostReferenceData } = useHostReferenceData('Announcement')

    await fetchHostReferenceData()
  },
}

const {
  clearErrors,
} = useApiError()
clearErrors()

const {
  fetchAppEnvironmentContext,
} = useAppEnvironmentContext()

const {
  userContext,
  isUserContextLoaded,
} = useUser2SessionContext()

const removeLoginPageQueryParameters = to => {
  const url = new URL(to.fullPath, window.location.origin)
  url.searchParams.delete('autoLogin')

  return encodeURIComponent(url.pathname + url.search)
}

router.beforeEach(async (to, from, next) => {
  log.debug('router.beforeEach', to)

  if (to.name === 'not-authorized') {
    return next()
  }

  // If there is a fallback query parameter, redirect to it
  if (to.query.fallback === 'true') {
    if (to.query.fallbackPath) {
      return next(to.query.fallbackPath)
    }
  }

  // if user session context is not loaded then load it and come back
  if (!isUserContextLoaded.value && to.name !== 'session-context') {
    return next({ name: 'session-context', query: { redirect: encodeURIComponent(to.fullPath) } })
  }

  // determine if the user logged in
  const isLoggedIn = userContext.value.isAuthenticated ?? false

  // if user is authenticated, load module reference data based on the requested module
  if (isLoggedIn) {
    await fetchAppEnvironmentContext()

    const handler = moduleReferenceDataHandlers[to.meta.module]
    if (handler) {
      await handler()
    }
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({
        name: 'login',
        query: {
          autoLogin: to.query.autoLogin,
          returnUrl: removeLoginPageQueryParameters(to),
        },
      })
    }

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: 'dashboard-default' })
  }

  // Load the requested page
  return next()
})

export default router
