import log from '@/core/logging/log'

/* eslint-disable max-classes-per-file */
class ApiError {
  constructor(problemDetail, errors = {}, errorType = '') {
    console.log('%cApiError', 'background: red; color: white;', problemDetail)
    log.error('%cApiError', problemDetail)

    this.instance = problemDetail.instance
    this.status = problemDetail.status
    this.title = problemDetail.title
    this.detail = problemDetail.detail
    this.type = problemDetail.type
    this.errorType = errorType ?? ''

    this.errors = errors
    this.isValidationError = errors ? Object.keys(errors).length > 0 : false

    this.display = true
    this.messageSent = false
    this.timestamp = Date.UTC()
  }
}
export default ApiError
