const secondary = [
  // Invoice View
  {
    path: '/features/finance/invoice/view/:id',
    name: 'feature-finance-invoice-view',
    component: () => import('@/features/finance/invoice/invoice-view/InvoiceView.vue'),
    meta: {
      breadcrumb: [{
        name: 'Invoice List',
        exact: true,
        link: true,
        to: { name: 'feature-finance-invoice-list' },
      },
      {
        name: 'Invoice View',
      }],
      layout: 'content',
      navActiveLink: 'feature-finance-invoice-list',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  {
    path: '/features/finance/invoice/new',
    name: 'feature-finance-invoice-new',
    component: () => import('@/features/finance/invoice/invoice-create/InvoiceCreate.vue'),
    props: route => ({ productId: route.query.productId }),
    meta: {
      breadcrumb: [{
        name: 'Invoice List',
        exact: true,
        link: true,
        to: { name: 'feature-finance-invoice-list' },
      },
      {
        name: 'New Invoice',
      }],
      layout: 'content',
      navActiveLink: 'feature-finance-invoice-list',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  // Member Invoice View
  {
    path: '/features/finance/invoice/list/member/:id',
    name: 'feature-member-invoice-view',
    component: () => import('@/features/finance/invoice/invoice-list/MemberInvoiceList.vue'),
    props: route => ({ contactId: route.params.id }),
    meta: {
      breadcrumb: [{
        name: 'Invoice List',
        exact: true,
        link: true,
        to: { name: 'feature-finance-invoice-list' },
      },
      {
        name: 'Member Invoices',
      }],
      layout: 'content',
      navActiveLink: 'feature-finance-invoice-list',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  // Invoice Pay
  {
    path: '/features/finance/invoice/pay',
    name: 'feature-finance-invoice-pay',
    component: () => import('@/features/finance/invoice/invoice-pay/Page.vue'),
    props: route => ({ envelopeId: route.query.env }),
    meta: {
      breadcrumb: [{
        name: 'Invoice List',
        exact: true,
        link: true,
        to: { name: 'feature-finance-invoice-list' },
      },
      {
        name: 'Invoice Payment',
      }],
      layout: 'content',
      navActiveLink: 'feature-finance-invoice-list',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  // Payment Confirmation
  {
    path: '/features/finance/invoice/confirmation',
    name: 'feature-finance-invoice-payment-confirmation',
    component: () => import('@/features/finance/invoice/invoice-pay/PaymentConfirmationPage.vue'),
    props: route => ({ paymentId: route.query.paymentId }),
    meta: {
      breadcrumb: [{
        name: 'Invoice List',
        exact: true,
        link: true,
        to: { name: 'feature-finance-invoice-list' },
      },
      {
        name: 'Payment Confirmation',
      }],
      layout: 'content',
      navActiveLink: 'feature-finance-invoice-list',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  // Invoice Member Pay
  {
    path: '/features/finance/invoice/member/pay',
    name: 'feature-finance-invoice-member-pay',
    component: () => import('@/features/finance/invoice/invoice-member-pay/Page.vue'),
    props: route => ({ invoiceId: route.query.invoiceId, encodedParams: route.query.p }),
    meta: {
      layout: 'blank',
      module: 'finance',
      resource: 'Invoice',
      action: 'pay',
    },
  },

  // Invoice Member Payment Confirmation
  {
    path: '/features/finance/invoice/member/confirmation',
    name: 'feature-finance-invoice-member-payment-confirmation',
    component: () => import('@/features/finance/invoice/invoice-member-pay/PaymentConfirmationPage.vue'),
    props: route => ({ paymentId: route.query.paymentId }),
    meta: {
      layout: 'blank',
      navActiveLink: 'feature-finance-invoice-list',
      module: 'finance',
      resource: 'Invoice',
      action: 'pay',
    },
  },

  // Product View
  {
    path: '/features/finance/product/view/:id',
    name: 'feature-finance-product-view',
    component: () => import('@/features/finance/product/product-view/ProductView.vue'),
    meta: {
      breadcrumb: [{
        name: 'Product List',
        exact: true,
        to: { name: 'feature-finance-product-list' },

      },
      {
        name: 'Product View',
      }],
      layout: 'content',
      navActiveLink: 'feature-finance-product-list',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  // Create Product
  {
    path: '/features/finance/product/new',
    name: 'feature-finance-product-new',
    component: () => import('@/features/finance/product/product-actions/NewProductStepper.vue'),
    meta: {
      breadcrumb: [{
        name: 'Product List',
        exact: true,
        to: { name: 'feature-finance-product-list' },

      },
      {
        name: 'New Product',
      }],
      layout: 'content',
      navActiveLink: 'feature-finance-product-list',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  // Finance Reports View
  {
    path: '/features/finance/reports/view/:id',
    name: 'feature-finance-report-view',
    component: () => import('@/features/finance/reports/report-view/ReportView.vue'),
    meta: {
      breadcrumb: [{
        name: 'Finance Report List',
        exact: true,
        to: { name: 'feature-finance-reports-list' },
      },
      {

        name: 'Report View',
      }],
      layout: 'content',
      navActiveLink: 'feature-finance-reports-list',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  // Compose Email View
  {
    path: '/features/finance/email/compose',
    name: 'feature-finance-compose-email',
    component: () => import('@/features/finance/communication/pages/compose/ComposeEmail.vue'),
    props: route => ({ envelopeId: route.query.env }),
    meta: {
      breadcrumb: [
        {
          name: 'Invoice List',
          to: { name: 'feature-finance-invoice-list' },
        },
        {
          name: 'Compose Email',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-finance-invoice-list',
      module: 'finance',
      resource: 'Tenant',
    },
  },
]

export default secondary
