<script>
import { ref, computed, watch } from '@vue/composition-api'
import { required, regexValidator } from '@core/utils/validation'
import { mdiCalendar } from '@mdi/js'
import dayjs from 'dayjs'

// https://www.freecodecamp.org/news/javascript-keycode-list-keypress-event-key-codes/
const digitKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
const specialKeys = ['Backspace', 'Delete', 'Tab', 'Enter', 'Escape',
  'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp',
  'c', 'v', 'x', 'z',
  'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9', 'Divide', '/']

export default {
  name: 'AppDatePicker',
  components: {},
  emit: ['update:modelValue'],
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
    promptText: {
      type: String,
      required: false,
      default: 'Date',
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },

  },
  emits: [],
  setup(props, context) {
    // =========================================
    // properties
    // =========================================
    const localModel = computed({
      get() {
        return props.modelValue
      },
      set(newValue) {
        dateFormattedText.value = formatDate(newValue)
        context.emit('update:modelValue', newValue)
      },
    })

    watch(
      () => localModel.value,
      async (newValue, oldValue) => {
        dateFormattedText.value = formatDate(newValue)
      },
      { deep: true },
    )

    const localPromptText = computed(() => props.promptText)

    // =========================================
    // menu
    // =========================================
    const menu = ref(false)

    const openMenu = () => {
      menu.value = true
    }

    // =========================================
    // textbox
    // =========================================
    const clearable = computed(() => !props.isRequired && (!(context.attrs.readonly || context.attrs.readonly === '')))

    const onIconClick = () => {
      openMenu()
    }

    const onClickClear = () => {
      localModel.value = ''
    }

    const onClickOutside = () => {
      dateFormattedText.value = formatDate(localModel.value)
    }

    const onBlur = selectedDate => {
      if (!selectedDate && !isDate(selectedDate)) {
        return null
      }

      if (selectedDate === '') {
        localModel.value = ''

        return null
      }

      localModel.value = dayjs(selectedDate).format('YYYY-MM-DD')

      return localModel.value // Return the formatted date
    }

    const onKeyDown = e => {
      if (e.key === '/' && dateFormattedText.value.endsWith('/')) {
        e.stopPropagation()
        e.preventDefault()

        return false
      }

      if (e.key === 'Delete') {
        localModel.value = ''
      }

      if (specialKeys.includes(e.key)) {
        return true
      }

      if (digitKeys.includes(e.key)) {
        return true
      }

      e.stopPropagation()
      e.preventDefault()

      return false
    }

    const onKeyUp = e => {
      if (digitKeys.includes(e.key)) {
        if (dateFormattedText.value.length === 2 || dateFormattedText.value.length === 5) {
          dateFormattedText.value += '/'
        }
      }
    }

    // This regex ensures that the date must be in the format MM/DD/YYYY and the year must be between 1753 and 9999
    const dateRegex = '^(0[1-9]|1[0-2])/(0[1-9]|1[0-9]|2[0-9]|3[01])/((175[3-9]|17[6-9][0-9]|1[89][0-9]{2}|[2-9][0-9]{3})|9999)$'
    const validators = computed(() => (props.isRequired ? [required, regexValidator(dateFormattedText.value, dateRegex)] : [regexValidator(dateFormattedText.value, dateRegex)]))

    const isDate = dataParam => {
      let isValid = dayjs(dataParam, 'YYYY-MM-DD', true).isValid()
      if (isValid) return isValid

      isValid = dayjs(dataParam, 'MM/DD/YYYY', true).isValid()
      if (isValid) return isValid

      return isValid
    }

    const formatDate = selectedDate => {
      if (!isDate(selectedDate)) return null

      return dayjs(selectedDate).format('MM/DD/YYYY')
    }

    const dateFormattedText = ref(formatDate(localModel.value))

    // =========================================
    // date picker
    // =========================================
    // const pickerDate = computed(() => {
    //   if (menu.value) return null

    //   const d = menu.value && !localModel.value
    //     ? dayjs().toISOString().substr(0, 10)
    //     : dayjs(localModel.value).toISOString().substr(0, 10)

    //   console.log('pickerDate', d)

    //   return d
    // })

    // =========================================
    // lifecycle
    // =========================================

    // =========================================
    // return
    // =========================================
    return {
      // properties
      localModel,
      localPromptText,

      // ui
      clearable,

      // menu
      menu,

      // textbox
      dateFormattedText,
      onIconClick,
      onBlur,
      onClickOutside,
      onClickClear,
      onKeyDown,
      onKeyUp,

      // date picker

      // standard
      validators,
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>

<template>
  <v-menu
    v-model="menu"
    :close-on-click="true"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <span class="d-flex align-start">
        <!-- <v-icon
          color="primary"
          class="mr-2"
          v-on="on"
        >{{ icons.mdiCalendar }}</v-icon> -->

        <v-text-field
          v-model="dateFormattedText"
          :append-icon="icons.mdiCalendar"
          :rules="validators"
          :clearable="clearable"
          v-bind="{...attrs, ...$attrs}"
          :label="localPromptText"
          placeholder="mm/dd/yyyy"
          color="primary"
          outlined
          dense
          hide-details
          @click:clear="onClickClear"
          @click:append="onIconClick"
          @click:outside="onClickOutside"
          @keydown="onKeyDown"
          @keyup="onKeyUp"
          @blur="onBlur(dateFormattedText)"
        ></v-text-field>
      </span>
    </template>

    <v-date-picker
      v-model="localModel"
      no-title
      active-picker="DATE"
      v-bind="$attrs"
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<style lang="scss" scoped>
// div {
//   border: 0px solid black;
// }
</style>
