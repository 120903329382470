<script>
import { mdiClose } from '@mdi/js'
import UserAvatarDisplay from '@/core/components/app-bar/user-menu/UserAvatarDisplay.vue'
import SignOutControl from '@/core/components/signout/SignOutControl.vue'
import OrganizationContextSwitcher from '@/core/components/app-bar/organization-context-menu/OrganizationContextSwitcher.vue'

export default {
  components: {
    UserAvatarDisplay,
    SignOutControl,
    OrganizationContextSwitcher,
  },
  model: {
    prop: 'isUserMenuSidebarActive',
    event: 'update:is-user-menu-sidebar-active',
  },
  props: {
    isUserMenuSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    // =========================================
    // return
    // =========================================
    return {
      // standard
      validators: {},
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<template>
  <div class="app-customizer">
    <v-navigation-drawer
      :value="isUserMenuSidebarActive"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      @input="(val) => $emit('update:is-user-menu-sidebar-active', val)"
    >
      <!-- Heading -->
      <div class="app-customizer-header customizer-section py-3">
        <UserAvatarDisplay />

        <v-btn
          icon
          class="icon-customizer-close"
          @click="$emit('update:is-user-menu-sidebar-active', false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div class="ml-2 my-2">
        <SignOutControl />
      </div>

      <v-divider></v-divider>

      <div class="customizer-section">
        <OrganizationContextSwitcher />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

// div {
//   border: 1px solid black;
// }

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 15 !important;
  position: absolute;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
    z-index: 15 !important;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
