const primary = [
  // List Meetings
  {
    name: 'feature-meeting-list',
    path: '/features/meeting/list',
    component: () => import('@/features/meeting/domain/pages/meeting-list/List.vue'),
    meta: {
      layout: 'content',
      module: 'meeting',
      resource: 'Tenant',
    },
  },

  // List Meeting Reports
  {
    name: 'feature-meeting-reports-list',
    path: '/features/meeting/reports/list',
    component: () => import('@/features/meeting/reports/report-list/ReportList.vue'),
    meta: {
      layout: 'content',
      module: 'meeting',
      resource: 'Tenant',
    },
  },
]

export default primary
