import { mdiFileDocumentOutline } from '@mdi/js'

export default [
  {
    title: 'Documents',
    icon: mdiFileDocumentOutline,
    resource: 'Tenant',
    to: 'feature-document-list',
  },
]
