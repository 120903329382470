// sendLog.js
function sendLogToServer(level, message) {

  // TODO: Implement sending logs to server

  //   fetch('https://server.com/api/logs', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       level,
  //       message,
  //       timestamp: new Date().toISOString(),
  //     }),
  //   })
  //     .catch(error => console.error('Error sending log to server:', error))
  // }
}

export default sendLogToServer
