<script>
import { getCurrentInstance, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'

export default {
  name: 'AbilityWatcher',
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const {
      organizationContext,
    } = useUser2SessionContext()

    watch(
      () => organizationContext.value.abilities,
      async (newValue, oldValue) => {
        if (vm.$ability) {
          // debugger
          vm.$ability.update(newValue)

          // Refresh the current page (not quite working yet...)
          // conflicting with sessioncontext page so maybe ignore when route has redirect uri param
          // console.log('refreshing page', router.currentRoute.fullPath)
          // router.push(router.currentRoute.fullPath).catch(() => {})
          // router.go(0)
        }
      },
      { deep: true },
    )

    return {
      organizationContext,
    }
  },
}
</script>

<template>
  <div>
    <!-- <pre>abilities: {{ userContext.abilities }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
// div {
//   border: 1px solid black;
// }
</style>
