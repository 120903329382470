import { mdiEmailEditOutline } from '@mdi/js'

export default [
  {
    title: 'Communication',
    icon: mdiEmailEditOutline,
    resource: 'Tenant',
    children: [
      {
        title: 'Compose Email',
        to: 'feature-communication-compose',
        resource: 'Tenant',
      },
      {
        title: 'Email Templates',
        to: 'feature-communication-list',
        resource: 'Tenant',
      },

      // {
      //   title: 'Email Log',
      //   to: 'feature-communication-email-log-list',
      //   resource: 'Tenant',
      // },

      // {
      //   title: 'Email Log',
      //   to: { name: 'feature-communication-activity-list', query: { activitySource: '1', activityType: '10' } },
      //   resource: 'Tenant',
      // },
    ],
  },
]
