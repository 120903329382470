<script>
import { ref } from '@vue/composition-api'
import { mdiCameraOutline } from '@mdi/js'

export default {
  name: 'PictureTaker',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['picture-taken'],
  setup(_, context) {
    // store data

    // input properties

    // status
    const isVisible = ref(false)

    // form
    const form = ref(null)
    const isValid = ref(false)

    const validateForm = () => {
      form.value.validate()
    }

    // camera
    const mediaStatus = ref({
      showMedia: false,
      showError: false,
      errorMessage: '',
    })

    function setMediaStatus(errorMessage) {
      if (errorMessage) {
        mediaStatus.value.showMedia = false
        mediaStatus.value.errorMessage = errorMessage
        mediaStatus.value.showError = true
      } else {
        mediaStatus.value.showError = false
        mediaStatus.value.errorMessage = ''
        mediaStatus.value.showMedia = true
      }
    }

    const canvas = ref(null)
    const video = ref(null)

    const videoSource = ref(null)
    const photoSource = ref(null)
    const videoDevices = ref(null)

    const clearPhoto = () => {
      if (canvas.value) {
        const canvasContext = canvas.value.getContext('2d')
        canvasContext.fillStyle = '#AAA'
        canvasContext.fillRect(0, 0, 500, 375)
        photoSource.value = null
      }
    }

    const startup = () => {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then(stream => {
          if (!videoSource.value) {
            videoSource.value = stream
          }
          setMediaStatus('')
        })
        .catch(err => {
          setMediaStatus(err)
        })
      clearPhoto()
    }

    async function getConnectedDevices() {
      await navigator.mediaDevices.enumerateDevices().then(devices => {
        videoDevices.value = devices.filter(device => device.kind === 'videoinput')
      })
    }

    const takePicture = () => {
      const canvasContext = canvas.value.getContext('2d')
      canvasContext.drawImage(video.value, 0, 0, 500, 375)
      const data = canvas.value.toDataURL('image/png')
      photoSource.value = data
    }

    const stopCamera = () => {
      if (videoSource.value) {
        videoSource.value.getTracks().forEach(track => track.stop())
        videoSource.value = null
      }
    }

    // dialog
    const closeDialog = () => {
      stopCamera()
      clearPhoto()
      form.value.resetValidation()
      isVisible.value = false
    }

    const openDialog = () => {
      startup()
      getConnectedDevices()
    }

    const submitForm = () => {
      if (isValid.value) {
        if (photoSource.value !== null) {
          context.emit('picture-taken', photoSource.value)
          closeDialog()
        }
        isVisible.value = false
      } else {
        // display form validation errors
        validateForm()
      }
    }

    return {
      isVisible,

      form,
      isValid,
      validateForm,
      submitForm,

      openDialog,
      closeDialog,

      mediaStatus,
      canvas,
      video,
      videoSource,
      photoSource,
      videoDevices,
      takePicture,
      clearPhoto,

      validators: {},
      icons: {
        mdiCameraOutline,
      },
    }
  },
}
</script>

<template>
  <v-dialog
    v-model="isVisible"
    max-width="650px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :disabled="disabled"
        block
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="openDialog"
      >
        <v-icon class="me-1">
          {{ icons.mdiCameraOutline }}
        </v-icon>
        <span>Take Photo</span>
      </v-btn>
    </template>

    <v-card v-show="mediaStatus.showMedia">
      <v-card-text>
        <v-form
          ref="form"
          v-model="isValid"
          @submit.prevent="submitForm"
        >
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <div
                v-show="photoSource === null"
              >
                <video
                  ref="video"
                  width="500"
                  height="375"
                  :srcObject.prop="videoSource"
                  autoplay
                >
                  Video stream not available.
                </video>
                <div class="d-flex justify-center mt-3">
                  <v-btn
                    color="primary"
                    @click="takePicture"
                  >
                    <v-icon class="me-1">
                      {{ icons.mdiCameraOutline }}
                    </v-icon>
                    <span class="d-none d-sm-block">Take picture</span>
                  </v-btn>
                </div>
              </div>
              <div v-show="photoSource !== null">
                <canvas
                  ref="canvas"
                  width="500"
                  height="375"
                />
                <div class="d-flex justify-center mt-3">
                  <v-btn
                    color="primary"
                    @click="clearPhoto"
                  >
                    <v-icon class="me-1">
                      {{ icons.mdiCameraOutline }}
                    </v-icon>
                    <span class="d-none d-sm-block">Retake picture</span>
                  </v-btn>
                </div>
              </div>
            </v-col>

            <!-- action buttons -->
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :disabled="photoSource == null"
              >
                OK
              </v-btn>
              <v-btn
                outlined
                color="secondary"
                class="me-3"
                @click="closeDialog"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card v-show="mediaStatus.showError">
      <v-card-title class="headline justify-center">
        Error loading media source
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center mt-3"
          >
            {{ mediaStatus.errorMessage }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center mt-3"
          >
            <v-btn
              outlined
              color="error"
              class="me-3"
              @click="closeDialog"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
</style>
