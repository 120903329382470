<script>
export default {
  name: 'AppSelect',
  emit: ['update:modelValue'],
  components: { },
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [String, Number, Array, Object],
      required: false,
      default: () => [],
    },
    promptText: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <div>
    <v-select
      :value="modelValue"
      v-bind="{ ...$attrs }"
      :label="promptText || $attrs.label"
      :placeholder="promptText || $attrs.placeholder"
      :hide-details="hideDetails"
      outlined
      dense
      v-on="{ ...$listeners }"
      @input="v => $emit('update:modelValue', v)"
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot
          :name="scopedSlotName"
          v-bind="slotData"
        />
      </template>
      <template
        v-for="(_, slotName) in $slots"
        #[slotName]
      >
        <slot :name="slotName" />
      </template>
    </v-select>
  </div>
</template>
