const secondary = [
  // Committee View
  {
    path: '/features/committees/:committeeId/view',
    name: 'feature-committee-view',
    component: () => import('@/features/committee/domain/pages/view/Page.vue'),
    meta: {
      layout: 'content',
      module: 'committee',
      resource: 'Tenant',
      navActiveLink: 'feature-committee-list',
      breadcrumb: [
        {
          name: 'Committee List',
          exact: true,
          to: { name: 'feature-committee-list' },
        },
        {
          name: 'Committee View',
        },
      ],
    },
  },

  // Add Committee Member
  {
    path: '/features/committees/:committeeId/members/add',
    name: 'feature-committee-member-add',
    component: () => import('@/features/committee/domain/pages/add-committee-member/Page.vue'),
    meta: {
      layout: 'content',
      module: 'committee',
      resource: 'Tenant',
      navActiveLink: 'feature-committee-list',
      breadcrumb: [
        {
          name: 'Committee List',
          exact: true,
          to: { name: 'feature-committee-list' },
        },
        {
          name: 'Committee View',
          exact: true,
          to: { name: 'feature-committee-view' },
          parameterMap: { committeeId: 'committeeId' },
        },
        {
          name: 'Add Committee Member',
        },
      ],
    },
  },

  // Commitee Report View
  {
    path: '/features/committee/reports/view/:id',
    name: 'feature-committee-report-view',
    component: () => import('@/features/committee/reports/report-view/ReportView.vue'),
    meta: {
      layout: 'content',
      module: 'committee',
      resource: 'Tenant',
      navActiveLink: 'feature-committee-reports-list',
      breadcrumb: [
        {
          name: 'Committee Report List',
          exact: true,
          to: { name: 'feature-committee-reports-list' },
        },
        {

          name: 'Report View',
        },
      ],
    },
  },

  // Compose Email View
  {
    path: '/features/committee/email/compose',
    name: 'feature-committee-compose-email',
    component: () => import('@/features/committee/communication/pages/compose/ComposeEmail.vue'),
    props: route => ({ envelopeId: route.query.env }),
    meta: {
      layout: 'content',
      module: 'committee',
      resource: 'Tenant',
      navActiveLink: 'feature-committee-list',
      breadcrumb: [
        {
          name: 'Committee List',
          to: { name: 'feature-committee-list' },
        },
        {
          name: 'Compose Email',
        },
      ],
    },
  },
]

export default secondary
