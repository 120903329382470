import activity from '@/features/activity/_framework/navigation/vertical-nav'
import committee from '@/features/committee/_framework/navigation/vertical-nav'
import communication from '@/features/communication/_framework/navigation/vertical-nav'
import documents from '@/features/document/_framework/navigation/vertical-nav'
import finance from '@/features/finance/_framework/navigation/vertical-nav'
import meetings from '@/features/meeting/_framework/navigation/vertical-nav'
import reports from '@/features/report/_framework/navigation/vertical-nav'
import teams from '@/features/team/_framework/navigation/vertical-nav'
import membership from '@/features/user/_framework/navigation/vertical-nav'
import system from '@/views/system/_framework/navigation/vertical-nav'
import dashboard from '@/navigation/dashboard'
import development from '@/navigation/development'

export default [
  ...dashboard,

  ...membership,
  ...committee,
  ...teams,
  ...meetings,
  ...finance,
  ...communication,

  ...documents,
  ...reports,

  // ...activity,

  ...system,

  ...development,
]
