<script>

import { mdiMenu, mdiChatQuestionOutline, mdiAccountCogOutline } from '@mdi/js'
import {
  computed, onBeforeUnmount, onBeforeMount, ref, getCurrentInstance, watch,
} from '@vue/composition-api'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import log from '@/core/logging/log'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'

import useAppEnvironmentContext from '@/core/components/environment/useAppEnvironmentContext'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'

import useEventBus from '@/plugins/tiny-emitter/event'
import useNotifications from '@/features/notification/_framework/composables/useNotifications'

// App Bar Components
import NotificationBell from '@/features/notification/domain/components/app-bar/NotificationBell.vue'
import NotificationDrawer from '@/features/notification/domain/components/app-bar/NotificationDrawer.vue'

import AppBarUserMenu from '@/core/components/app-bar/user-menu/AppBarUserMenu.vue'
import AppBarUserMenuDrawer from '@/core/components/app-bar/user-menu/AppBarUserMenuDrawer.vue'

import OrganizationDisplayName from '@/core/components/app-bar/organization-context-menu/OrganizationDisplayName.vue'

import AbilityWatcher from '@/core/components/AbilityWatcher.vue'

export default {
  name: 'TopAppBar',
  emit: ['is-open-notification-drawer'],
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    NotificationBell,
    NotificationDrawer,

    AppCustomizer,

    AppBarUserMenu,
    AppBarUserMenuDrawer,

    OrganizationDisplayName,

    AbilityWatcher,
  },
  setup() {
    // =========================================
    // variables
    // =========================================

    // =========================================
    // user session context
    // =========================================
    const {
      organizationContext,
    } = useUser2SessionContext()

    // =========================================
    // application environment context
    // =========================================
    log.info('NODE_ENV:', process.env.NODE_ENV)

    const {
      appEnvironmentName,
      isProduction,
    } = useAppEnvironmentContext()

    // =========================================
    // notifications
    // =========================================
    const isNotificationSidebarActive = ref(false)
    const { onEvent, offEvent } = useEventBus()

    const {
      notificationList,
      dismissNotifications,
      fetchNotificationList,
    } = useNotifications()

    const refreshNotificationData = async () => {
      await fetchNotificationList(1)
    }

    const pushNotificationList = ref([])

    onEvent('ClientNotification', payload => {
      // console.log('ClientNotification', payload)

      // refreshNotificationData()
      pushNotificationList.value.push(payload)
    })

    const displayNotificationList = computed(() => [...notificationList.value, ...pushNotificationList.value])

    const removeNotifications = notificationIds => {
      const apiData = {
        dto: notificationIds,
      }
      dismissNotifications(apiData)
      notificationList.value = notificationList.value.filter(notification => !notificationIds.includes(notification.id))
      pushNotificationList.value = pushNotificationList.value.filter(notification => !notificationIds.includes(notification.id))
    }

    watch(
      () => organizationContext.value,
      async () => {
        await fetchNotificationList(1)
      },
    )

    // =========================================
    // theme settings
    // =========================================
    const isThemeCustomizerOpen = ref(false)

    const onThemeSettingClick = () => {
      isThemeCustomizerOpen.value = !isThemeCustomizerOpen.value
    }

    // =========================================
    // tawk
    // =========================================
    const vm = getCurrentInstance().proxy

    const onHelpClick = () => {
      vm.$tawkMessenger.toggle()
    }

    // =========================================
    // user menu
    // =========================================
    const isUserMenuSidebarActive = ref(false)

    // =========================================
    // lifecycle
    // =========================================
    onBeforeMount(async () => {
      await refreshNotificationData()

      if (vm.$tawkMessenger) {
        vm.$tawkMessenger.visitor = {
          name: 'Test User',
          email: 'testuser@email.com',
        }

      // vm.$tawkMessenger.setAttributes({
      //   TestAttribute: 'A1234',
      // }, error => {
      //   console.log(error)
      // })
      }
    })

    onBeforeUnmount(() => offEvent())

    // =========================================
    // return
    // =========================================
    return {
      // menu
      navMenuItems,

      // app bar
      isProduction,
      appEnvironmentName,

      // app bar - notifications
      isNotificationSidebarActive,
      onThemeSettingClick,
      displayNotificationList,
      removeNotifications,

      // app bar - theme settings
      isThemeCustomizerOpen,

      // app bar - tawk
      onHelpClick,

      // app bar - user menu
      isUserMenuSidebarActive,

      icons: {
        mdiMenu,
        mdiChatQuestionOutline,
        mdiAccountCogOutline,
      },
    }
  },
}
</script>

<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div class="navbar-content-container">
        <!-- Top Left -->
        <div
          class="d-flex align-center"
        >
          <!-- Menu hamburger -->
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>

          <!-- Organization Name -->
          <OrganizationDisplayName />
        </div>

        <!-- Environment Name -->
        <div class="me-3">
          <span
            v-if="!isProduction"
            class="text-2xl font-weight-semibold error--text"
          > {{ appEnvironmentName }} Environment</span>
        </div>
      </div>

      <!-- Top Right -->
      <div
        class="d-flex align-center"
      >
        <!-- Notification Bell -->
        <NotificationBell
          class="me-3"
          :count="displayNotificationList.length"
          @is-open-notification-drawer="isNotificationSidebarActive = !isNotificationSidebarActive"
        />

        <!-- Chat help -->
        <!-- <div class="me-3">
            <v-icon

              @click="onHelpClick"
            >
              {{ icons.mdiChatQuestionOutline }}
            </v-icon>
          </div> -->

        <!-- Theme customzier -->
        <!-- <div class="me-3">
            <v-icon @click="onThemeSettingClick">
              {{ icons.mdiAccountCogOutline }}
            </v-icon>
          </div> -->

        <!-- User Dropdown -->
        <AppBarUserMenu
          class="me-3"
          @is-open-user-menu-drawer="isUserMenuSidebarActive = !isUserMenuSidebarActive"
        />
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} American Inns of Court<span class="d-none d-md-inline">,
          All rights Reserved</span></span>
      </div>
    </template>

    <!-- drawers -->
    <AppCustomizer
      :show-button="false"
      :is-open="isThemeCustomizerOpen"
    />

    <NotificationDrawer
      v-model="isNotificationSidebarActive"
      :list="displayNotificationList"
      @dismiss-notifications="removeNotifications($event)"
    />

    <AppBarUserMenuDrawer
      v-model="isUserMenuSidebarActive"
    />

    <AbilityWatcher />
  </layout-content-vertical-nav>
</template>

<style lang="scss" scoped>
// div {
//   border: 1px solid black;
// }

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    >.d-flex>button.v-icon {
      display: none;
    }

    // ===

    &>.right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
