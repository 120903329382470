<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClockOutline } from '@mdi/js'

export default {
  name: 'AppTimePicker',
  model: {
    prop: 'vModel',
    event: 'update:model-changed',
  },
  props: {
    vModel: {
      required: true,
      default: null,
      validator(value) {
        return value === null || typeof value === 'string'
      },
    },
    label: {
      type: String,
      required: false,
      default: 'Select Time',
    },
  },
  setup(props, context) {
    const time = ref(props.vModel)
    const showMenu = ref(false)

    function notifyModelChange() {
      context.emit('update:model-changed', time.value)
    }

    function formatAMPM(hours, minutes) {
      const ampm = hours >= 12 ? 'PM' : 'AM'
      let formattedHours = hours % 12
      formattedHours = formattedHours || 12 // Convert hour '0' to '12'
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

      return `${formattedHours}:${formattedMinutes} ${ampm}`
    }

    function formatTime(event) {
      const timeStr = event.target.value.trim()?.toLowerCase()
      if (!timeStr) {
        return ''
      }

      let timeVal = null
      const timeRegex = /^(\d{1,2})[:]?(\d{2})?\s?(am|pm)?$/
      const match = timeRegex.exec(timeStr)
      if (match) {
        let hours = parseInt(match[1], 10)
        const minutes = match[2] ? parseInt(match[2], 10) : 0
        if (hours === 12) {
          hours = 0
        }
        if (match[3]) {
          if (match[3] === 'pm') {
            hours += 12
          }
        }
        timeVal = new Date()
        timeVal.setHours(hours)
        timeVal.setMinutes(minutes)
        timeVal.setSeconds(0)
        timeVal.setMilliseconds(0)
      }

      // Format time to HH:MM AM/PM format, excluding milliseconds
      return timeVal ? formatAMPM(timeVal.getHours(), timeVal.getMinutes()) : ''
    }

    // Watch for changes to vModel prop
    watch(
      () => props.vModel,
      () => {
        time.value = props.vModel
      },
    )

    return {
      time,
      showMenu,
      notifyModelChange,
      formatTime,
      icons: {
        mdiClockOutline,
      },
    }
  },
}
</script>

<template>
  <div id="time-picker-control">
    <v-menu
      ref="menu"
      v-model="showMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model.trim="time"
          :prepend-inner-icon="icons.mdiClockOutline"
          :label="label"
          :placeholder="label"
          dense
          outlined
          clearable
          v-bind="attrs"
          v-on="on"
          @blur="formatTime($event)"
        >
        </v-text-field>
      </template>
      <v-time-picker
        v-if="showMenu"
        v-model="time"
        format="24hr"
        outlined
        dense
        hide-details
        :use-seconds="false"
        color="primary"
        @click:minute="$refs.menu.save(time)"
        @change="notifyModelChange"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<style scoped>
</style>
