import { mdiAccountCogOutline, mdiCogOutline } from '@mdi/js'

export default [
  // =========================================
  // Tenant Settings
  // =========================================

  // {
  //   subheader: ' ',
  // },
  {
    title: 'Settings',
    icon: mdiCogOutline,
    resource: 'Tenant',
    to: 'settings-default',
  },

  // =========================================
  // Host Settings
  // =========================================
  {
    subheader: 'ADMINISTRATION',
  },
  {
    title: 'Host Settings',
    icon: mdiAccountCogOutline,
    children: [
      // {
      //   title: 'Content Messages',
      //   to: 'administration-content-message-list',
      // },
      {
        title: 'Announcements',
        to: 'administration-announcement-list',
      },
      {
        title: 'Communication',
        to: 'administration-communication-list',
      },

      // {
      //   title: 'Reports',
      //   to: 'administration-report-list',
      // },
    ],
  },
]
