import { ref } from '@vue/composition-api'
import useApiError from '@/core/api/useApiError'
import constants from '@/features/notification/_framework/constants'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'
import store from '@/store'

export default function useNotifications() {
  // =========================================
  // register module(s)
  // =========================================

  // =========================================
  // error handling
  // =========================================
  const {
    addError,
  } = useApiError()

  // =========================================
  // user session context
  // =========================================
  const {
    userContext,
    organizationContext,
  } = useUser2SessionContext()

  // =========================================
  // notifications
  // =========================================
  const notificationList = ref([])

  // =========================================
  // api
  // =========================================
  const fetchNotificationList = async status => {
    try {
      const response = await store
        .dispatch(`${constants.NOTIFICATION_STORE_MODULE_NAME}/fetchNotificationList`,
          {
            routeParameters: {
              organizationId: organizationContext.value.organizationId,
              userId: userContext.value.userId,
            },
            queryParameters: {
              status,
            },
          })

      if (response.status === 200) {
        notificationList.value = JSON.parse(JSON.stringify(response.data.items))

        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  const dismissNotifications = async apiData => {
    try {
      const response = await store
        .dispatch(`${constants.NOTIFICATION_STORE_MODULE_NAME}/dismissNotifications`,
          {
            routeParameters: {
              organizationId: organizationContext.value.organizationId,
              userId: userContext.value.userId,
            },
            queryParameters: { },
            dto: apiData.dto,
          })

      if (response.status === 200) {
        return true
      }
      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  // =========================================
  // return
  // =========================================
  return {
    notificationList,

    dismissNotifications,
    fetchNotificationList,
  }
}
