import useApiError from '@/core/api/useApiError'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'
import store from '@/store'
import { computed } from '@vue/composition-api'

export default function useTenantReferenceData(feature) {
  // =========================================
  // register module(s)
  // =========================================

  // =========================================
  // variables
  // =========================================
  const featureName = `feature${feature}`
  const mutationName = `${featureName}/SET_TENANT_REFERENCE_DATA`
  const actionName = `${featureName}/fetchTenantReferenceData`
  const getterName = `${featureName}/getTenantReferenceData`

  // =========================================
  // error handling
  // =========================================
  const {
    addError,
  } = useApiError()

  // =========================================
  // user session context
  // =========================================
  const {
    organizationContext,
  } = useUser2SessionContext()

  // =========================================
  // reference data
  // =========================================
  const tenantReferenceData = computed({
    get: () => store.getters[getterName],
    set: newValue => {
      store.commit(mutationName, newValue)
    },
  })

  // =========================================
  // api
  // =========================================
  const fetchTenantReferenceData = async () => {
    try {
      if (tenantReferenceData.value && Object.keys(tenantReferenceData.value).length > 0) {
        return true
      }

      const response = await store.dispatch(actionName, {
        routeParameters: {
          organizationId: organizationContext.value.organizationId,
        },
        queryParameters: {},
      })

      if (response.status === 200) {
        tenantReferenceData.value = JSON.parse(JSON.stringify(response.data))

        return true
      }
      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  // =========================================
  // return
  // =========================================
  return {
    tenantReferenceData,

    fetchTenantReferenceData,
  }
}
