const primary = [
  {
    path: '/features/documents/document/list',
    name: 'feature-document-list',
    component: () => import('@/features/document/domain/pages/list/DocumentList.vue'),
    meta: {
      layout: 'content',
      module: 'document',
      resource: 'Tenant',
    },
  },

  // Settings
  {
    path: '/settings/default',
    name: 'settings-default',
    component: () => import('@/views/system/settings/tenant/Default.vue'),
    meta: {
      layout: 'content',
      module: 'document',
      resource: 'Tenant',
    },
  },
]

export default primary
