const primary = [
  // =========================================
  // Adminstration
  // =========================================
  {
    path: '/administration/content-message/list',
    name: 'administration-content-message-list',
    component: () => import('@/views/system/settings/host/Default.vue'),
    meta: {
      layout: 'content',
      module: 'administration',
      resource: 'Tenant',
    },
  },

  // =========================================
  // System Paths
  // =========================================
  {
    path: '/system/sessioncontext',
    name: 'session-context',
    component: () => import('@/views/system/domain/pages/SessionContext.vue'),
    props: route => ({ redirect: route.query.redirect }),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/system/login',
    name: 'login',
    component: () => import('@/views/system/domain/pages/Login.vue'),
    props: route => ({ returnUrl: route.query.returnUrl, autoLogin: route.query.autoLogin }),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/system/notauthorized',
    name: 'not-authorized',
    component: () => import('@/views/system/domain/pages/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/system/error404',
    name: 'error-404',
    component: () => import('@/views/system/domain/pages/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/system/fallback',
    name: 'fallback',
    component: () => import('@/views/system/domain/pages/Fallback.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

export default primary
