import apiService from '@/core/api/apiService'

export default {
  namespaced: true,
  state: {
    loadingCounter: 0,
    isLoading: false,
    shallContentShowOverlay: false,
    appEnvironmentContext: {},
  },
  getters: {
    getAppEnvironmentContext: state => state.appEnvironmentContext,
  },
  mutations: {
    SET_APP_ENVIRONMENT_CONTEXT(state, value) {
      state.appEnvironmentContext = value !== undefined ? value : {}
    },
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    INCREMENT_LOADING(state) {
      state.loadingCounter += 1
      if (state.loadingCounter < 0) {
        state.loadingCounter = 0
      }
      setTimeout(() => { state.isLoading = state.loadingCounter !== 0 }, 0)
    },
    DECREMENT_LOADING(state) {
      state.loadingCounter -= 1
      if (state.loadingCounter < 0) {
        state.loadingCounter = 0
      }
      setTimeout(() => { state.isLoading = state.loadingCounter !== 0 }, 0)
    },
  },
  actions: {
    fetchAppEnvironmentContext(ctx, payload) {
      return apiService.get('/moduleinfo/appenvironmentcontext',
        payload.queryParameters)
    },
  },
}
