import AppAutoComplete from './controls/AppAutoComplete.vue'
import AppCheckBox from './controls/AppCheckBox.vue'
import AppComboBox from './controls/AppComboBox.vue'
import AppSelect from './controls/AppSelect.vue'
import AppTextField from './controls/AppTextField.vue'
import AppTextarea from './controls/AppTextarea.vue'
import AppTimePicker from './controls/AppTimePicker.vue'
import AppDatePicker from './controls/AppDatePicker.vue'

export default {
  install(Vue) {
    Vue.component('app-select', AppSelect)
    Vue.component('app-combobox', AppComboBox)
    Vue.component('app-autocomplete', AppAutoComplete)
    Vue.component('app-text-field', AppTextField)
    Vue.component('app-textarea', AppTextarea)
    Vue.component('app-checkbox', AppCheckBox)
    Vue.component('app-time-picker', AppTimePicker)
    Vue.component('app-date-picker', AppDatePicker)
  },
}
