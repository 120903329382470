<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'
import useDynamicVh from '@core/utils/useDynamicVh'
import store from '@/store'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import IdleDialog from './views/system/domain/dialogs/IdleDialog.vue'

import ApiErrorAlertDisplayVue from '@/core/api/ApiErrorAlertDisplay.vue'

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    IdleDialog,
    ApiErrorAlertDisplayVue,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    const isIdle = computed(() => store.state.idleVue.isIdle)
    const showIdleDialog = ref(false)
    watch(
      () => isIdle.value,
      async (newValue, oldValue) => {
        if (newValue) {
          showIdleDialog.value = newValue
        }
      },
    )

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      isIdle,
      showIdleDialog,
    }
  },
}
</script>

<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <ApiErrorAlertDisplayVue />
    <IdleDialog :show-dialog.sync="showIdleDialog" />

    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
  </component>
</template>
