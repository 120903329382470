const primary = [
  {
    path: '/features/finance/invoice/list',
    name: 'feature-finance-invoice-list',
    component: () => import('@/features/finance/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      layout: 'content',
      module: 'finance',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/finance/product/list',
    name: 'feature-finance-product-list',
    component: () => import('@/features/finance/product/product-list/ProductList.vue'),
    meta: {
      layout: 'content',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  {
    path: '/features/finance/report/list',
    name: 'feature-finance-reports-list',
    component: () => import('@/features/finance/reports/report-list/ReportList.vue'),
    meta: {
      layout: 'content',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  // Host Settings
  {
    path: '/administration/report/list',
    name: 'administration-report-list',
    component: () => import('@/features/report/settings/host/pages/view/ReportSettings.vue'),
    meta: {
      layout: 'content',
      module: 'administration',
    },
  },
]

export default primary
