<script>
import { computed } from '@vue/composition-api'
import { getVuetify } from '@/@core/utils'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'

export default {
  name: 'AppBarOrganizationContext',
  components: {},
  setup() {
  // =========================================
  // user session context
  // =========================================
    const {
      organizationContext,
    } = useUser2SessionContext()

    const $vuetify = getVuetify()

    const organizationNameStyle = computed(() => {
      if ($vuetify.breakpoint.xsOnly) {
        return {
          // color: 'red !important',
          fontSize: '15px',
        }
      }
      if ($vuetify.breakpoint.smAndDown) {
        return {
          // color: 'green !important',
          fontSize: '24px',
        }
      }

      return {
        // color: 'black',
        fontSize: '32px',
      }
    })

    return {
      organizationContext,
      organizationNameStyle,
    }
  },
}
</script>

<template>
  <div>
    <h1 :style="organizationNameStyle">
      {{ organizationContext.organizationName }}
    </h1>
  </div>
</template>

<style lang="scss" scoped>
// div {
//   border: 1px solid black;
// }
</style>
