const secondary = [
  {
    // Core
    name: 'feature-communication-view',
    path: '/features/communication/view/:id?',
    component: () => import('@/features/communication/domain/pages/view/CommunicationTemplateView.vue'),
    meta: {
      layout: 'content',
      module: 'communication',
      resource: 'Tenant',
      navActiveLink: 'feature-communication-list',
      breadcrumb: [
        {
          name: 'Email Templates',
          exact: true,
          to: { name: 'feature-communication-list' },
        },
        {
          name: 'Email Template View',
        },
      ],
    },
  },
  {
    name: 'feature-communication-activity-view',
    path: '/features/communication/activity/view/:id',
    component: () => import('@/features/communication/activity/pages/view/ActivityView.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Communication Activity List',
          exact: true,
          to: { name: 'feature-communication-activity-list' },
        },
        {
          name: 'Communication Activity View',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-communication-activity-list',
      module: 'communication',
      resource: 'Tenant',
    },
  },
  {
    name: 'feature-communication-samples-view',
    path: '/features/communication/samples/view/:id',
    component: () => import('@/features/communication/domain/pages/view/HostCommunicationSampleView.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Email Templates',
          exact: true,
          to: { name: 'feature-communication-list' },
        },
        {
          name: 'Sample Template View',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-communication-list',
      module: 'communication',
      resource: 'Tenant',
    },
  },

  // Host Settings
  {
    name: 'feature-communication-settings-host-view',
    path: '/administration/communication/view/:id?',
    component: () => import('@/features/communication/settings/host/pages/view/HostCommunicationView.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Communication Template List',
          exact: true,
          to: { name: 'administration-communication-list' },
        },
        {
          name: 'Communication Template View',
        },
      ],
      layout: 'content',
      navActiveLink: 'administration-communication-list',
      module: 'communication',
      resource: 'Tenant',
    },
  },
  {
    name: 'feature-email-job-detail',
    path: '/features/communication/view/emailJobDetail/:id?/:module?/:type?',
    component: () => import('@/features/communication/domain/pages/view/EmailJobDetail.vue'),
    meta: {
      layout: 'content',
      module: 'communication',
      resource: 'Tenant',
      navActiveLink: 'feature-communication-email-log-list',
      breadcrumb: [
        {
          name: 'Email Log',
          exact: true,
          to: { name: 'feature-communication-email-log-list' },
        },
        {
          name: 'Email Task Detail',
        },
      ],
    },
  },
]

export default secondary
