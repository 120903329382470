<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import AbilityWatcher from '@/core/components/AbilityWatcher.vue'

export default {
  components: {
    LayoutBlank,
    AbilityWatcher,
  },
}
</script>

<template>
  <div>
    <layout-blank>
      <slot></slot>
    </layout-blank>
    <AbilityWatcher />
  </div>
</template>

<style>
</style>
