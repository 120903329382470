<script>
export default {
  name: 'AppTextarea',
  emit: ['update:modelValue'],
  components: { },
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },
    promptText: {
      type: String,
      default: null,
    },
  },
}
</script>

<template>
  <v-textarea
    :value="modelValue"
    v-bind="$attrs"
    :label="promptText || $attrs.label"
    :placeholder="promptText || $attrs.placeholder"
    outlined
    dense
    hide-details
    v-on="{ ...$listeners }"
    @input="v => $emit('update:modelValue', v)"
  >
    <template
      v-for="(_, scopedSlotName) in $scopedSlots"
      #[scopedSlotName]="slotData"
    >
      <slot
        :name="scopedSlotName"
        v-bind="slotData"
      />
    </template>
    <template
      v-for="(_, slotName) in $slots"
      #[slotName]
    >
      <slot :name="slotName" />
    </template>
  </v-textarea>
</template>
