<template>
  <v-app>
    <v-main>
      <slot></slot>
      <LoadingSpinner />
    </v-main>
  </v-app>
</template>

<script>
import LoadingSpinner from '@/core/components/loading-spinner/LoadingSpinner.vue'

export default {
  components: {
    LoadingSpinner,
  },
  setup() {
    return {
    }
  },
}
</script>

<style>
</style>
