const primary = [
  {
    path: '/features/activity/list',
    name: 'feature-activity-list',
    component: () => import('@/features/activity/domain/pages/list/ActivityList.vue'),
    props: route => ({ activitySource: route.query.activitySource, activityType: route.query.activityType }),
    meta: {
      layout: 'content',
      module: 'activity',
      resource: 'Tenant',
    },
  },
]

export default primary
