<script>
import { ref } from '@vue/composition-api'
import UserAvatarDisplay from '@/core/components/app-bar/user-menu/UserAvatarDisplay.vue'

export default {
  name: 'AppBarUserMenu',
  components: {
    UserAvatarDisplay,
  },
  props: { },

  setup() {
    const isOpenUserMenuDrawer = ref(false)

    // =========================================
    // return
    // =========================================
    return {
      isOpenUserMenuDrawer,

      // standard
      validators: {},
      icons: {},
    }
  },
}
</script>

<template>
  <div id="app-bar-user-menu-2">
    <div
      class="cursor-pointer"
      @click.stop="$emit('is-open-user-menu-drawer')"
    >
      <UserAvatarDisplay
        :show-name-and-role="false"
        :allow-avatar-edit="false"
      />
    </div>
  </div>
</template>
