<script>
import { computed } from '@vue/composition-api'
import { mdiBellOutline } from '@mdi/js'

export default {
  name: 'NotificationBell',
  components: { },
  emit: ['is-open-notification-drawer'],
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    // =========================================
    // TYPE:            COMPONENT
    // PROP (BINDING)   1 WAY
    // DIRECT API:      NO
    // FORM DATA:       NO
    // =========================================

    // =========================================
    // error handling
    // =========================================

    // =========================================
    // user session context
    // =========================================

    // =========================================
    // properties
    // =========================================
    const localCount = computed(() => props.count)

    // =========================================
    // return
    // =========================================
    return {
      // properties
      localCount,

      // standard
      validators: {},
      icons: {
        mdiBellOutline,
      },
    }
  },
}
</script>

<template>
  <div>
    <div
      class="cursor-pointer"
      @click.stop="$emit('is-open-notification-drawer')"
    >
      <v-badge
        v-if="localCount > 0"
        top
        color="primary"
        overlap
        :content="localCount"
      >
        <v-icon>
          {{ icons.mdiBellOutline }}
        </v-icon>
      </v-badge>
      <v-icon v-else>
        {{ icons.mdiBellOutline }}
      </v-icon>
    </div>
  </div>
</template>
