import { mdiClipboardAccountOutline } from '@mdi/js'

export default [
  {
    title: 'Activity',
    icon: mdiClipboardAccountOutline,
    resource: 'Tenant',
    children: [
      {
        title: 'Activity List',
        to: 'feature-activity-list',
        resource: 'Tenant',
      },
    ],
  },
]
