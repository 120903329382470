import { mdiViewDashboardOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiViewDashboardOutline,
    to: 'dashboard-default',
    resource: 'Tenant',
  },
]
