import { mdiCalendarCheckOutline } from '@mdi/js'

export default [
  {
    title: 'Meetings',
    icon: mdiCalendarCheckOutline,
    resource: 'Tenant',
    children: [
      {
        title: 'Meeting List',
        to: 'feature-meeting-list',
        resource: 'Tenant',
      },
      {
        title: 'Meeting Reports',
        to: 'feature-meeting-reports-list',
        resource: 'Tenant',
      },
    ],
  },
]
