const VueMouseflow = {
  createMfq() {
    window._mfq = window._mfq || []
  },
  addTrackingScript(tracking_key) {
    const mf = document.createElement('script')
    mf.type = 'text/javascript'
    mf.defer = true
    mf.async = true
    mf.src = `//cdn.mouseflow.com/projects/${tracking_key}.js`

    document.getElementsByTagName('head')[0].appendChild(mf)
  },
  install(Vue, options) {
    Vue.prototype.$mf = {
      push: this.push,
      logRouteChange: this.logRouteChange,
    }

    if (options.tracking_key === undefined) {
      throw new Error('No Mouseflow tracking key specified.')
    }

    this.createMfq()
    this.addTrackingScript(options.tracking_key)
  },
  push(...args) {
    window._mfq.push([...args])
  },
  logRouteChange(to, options) {
    const path = (options || {}).includeRouteParams === false
      ? to.matched[to.matched.length - 1].path
      : to.fullPath

    this.push('newPageView', path)
  },
}

export default VueMouseflow
