const dashboard = [
  {
    path: '/dashboards/default',
    name: 'dashboard-default',
    component: () => import('@/views/dashboards/default/Default.vue'),
    meta: {
      layout: 'content',
      resource: 'Tenant',
    },
  },
]

export default dashboard
