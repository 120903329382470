import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_COMMUNICATION}/referencedata`, payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters,
    )
  },

  //= =============================================
  // Contacts
  //= =============================================
  fetchContacts(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/contacts`,
      payload.queryParameters,
    )
  },

  //= =============================================
  // Communication Templates
  //= =============================================
  createCommunicationTemplate(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/communicationTemplates`,
      payload.queryParameters,
      payload.dto,
    )
  },

  updateCommunicationTemplate(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/communicationTemplates/${payload.routeParameters.templateId}`,
      payload.queryParameters,
      payload.dto,
    )
  },

  deleteCommunicationTemplate(ctx, payload) {
    return apiService.delete(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/communicationTemplates/${payload.routeParameters.templateId}`,
      payload.queryParameters,
    )
  },

  copyCommunicationTemplate(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/communicationTemplates/${payload.routeParameters.templateId}/copy`,
      payload.queryParameters,
      payload.dto,
    )
  },

  fetchCommunicationTemplate(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/communicationTemplates/${payload.routeParameters.templateId}`,
      payload.queryParameters,
    )
  },

  fetchCommunicationTemplates(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/communicationTemplates`,
      payload.queryParameters,
    )
  },

  //= =============================================
  // Host Communication Templates
  //= =============================================
  createHostCommunicationTemplate(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/communicationTemplates`,
      payload.queryParameters,
      payload.dto,
    )
  },

  updateHostCommunicationTemplate(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/communicationTemplates/${payload.routeParameters.templateId}`,
      payload.queryParameters,
      payload.dto,
    )
  },

  deleteHostCommunicationTemplate(ctx, payload) {
    return apiService.delete(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/communicationTemplates/${payload.routeParameters.templateId}`,
      payload.queryParameters,
    )
  },

  copyHostCommunicationTemplate(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/communicationTemplates/${payload.routeParameters.templateId}/copy`,
      payload.queryParameters,
      payload.dto,
    )
  },

  fetchHostCommunicationTemplate(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/communicationTemplates/${payload.routeParameters.templateId}`,
      payload.queryParameters,
    )
  },

  fetchHostCommunicationTemplates(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/communicationTemplates`,
      payload.queryParameters,
    )
  },

  //= =============================================
  // Email Jobs
  //= =============================================
  uploadBlobs(ctx, payload) {
    return apiService.postBlob(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/blobs`,
      payload.queryParameters,
      payload.dto,
    )
  },

  createEmailJob(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/emailjobs`,
      payload.queryParameters,
      payload.dto,
    )
  },

  updateEmailJob(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/updateEmailjobs`,
      payload.queryParameters,
      payload.dto,
    )
  },

  //= =============================================
  // Contacts
  //= =============================================
  fetchPendingEmails(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/pendingEmails`,
      payload.queryParameters,
    )
  },

  deletePendingEmail(ctx, payload) {
    return apiService.delete(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/deleteEmailJob/${payload.routeParameters.emailJobId}`,
      payload.queryParameters,
    )
  },

  fetchSentEmails(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.organizationId}/sentEmails`,
      payload.queryParameters,
    )
  },

  fetchEmailTaskDetail(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.emailJobId}/EmailTaskDetail`,
      payload.queryParameters,
    )
  },
  fetchEmailJobDetail(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_COMMUNICATION}/organizations/${payload.routeParameters.emailJobId}/EmailJobDetail`,
      payload.queryParameters,
    )
  },
}
