<script>
export default {
  name: 'LoadingSpinner',
}
</script>

<template>
  <v-overlay
    :value="$store.state.app.isLoading"
    z-index="1000"
  >
    <div class="text-center">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
        class="mb-4"
      ></v-progress-circular>
      <div>Just a moment</div>
    </div>
  </v-overlay>
</template>

<style lang="scss" scoped>
.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
