<script>
export default {
  name: 'AppCheckBox',
  inheritAttrs: false,
  props: {
  },
}
</script>

<template>
  <v-checkbox
    v-bind="$attrs"
    outlined
    dense
    hide-details
  />
</template>
