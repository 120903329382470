<script>
import {
  computed, ref, watch,
} from '@vue/composition-api'
import {
  mdiPencilOutline,
} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import ContactPictureEdit from '@/features/user/domain/components/ContactPictureEdit.vue'

export default {
  name: 'ContactPictureEditDialog',
  components: {
    ContactPictureEdit,
  },
  emit: ['update:showDialog'],
  props: {
    inputModel: {
      type: Object,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowTakePhoto: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Edit Profile Picture',
    },
  },
  setup(props, context) {
    // =========================================
    // variables
    // =========================================

    // =========================================
    // user session context
    // =========================================

    // =========================================
    // properties
    // =========================================
    const localInputModel = computed(() => props.inputModel)
    const localShowButton = computed(() => props.showButton)
    const localTitle = computed(() => props.title)

    const pictureEditSource = ref(null)
    const croppedPicture = ref(null)

    watch(
      () => localInputModel.value,
      async (newValue, oldValue) => {
        pictureEditSource.value = {
          profilePictureUrl: newValue.profilePictureUrl,
          firstLast: newValue.firstLast,
        }
      },
      { deep: true, immediate: true },
    )

    // =========================================
    // reference data
    // =========================================

    // =========================================
    // form
    // =========================================

    // =========================================
    // feature
    // =========================================

    const submitForm = async () => {
      const apiData = {
        contactId: localInputModel.value.id,
        dto: croppedPicture.value,
        queryParameters: {},
      }

      if (await props.save(apiData)) {
        closeDialog()
        context.emit('picture-saved')

        return true
      }

      return false
    }

    const handlePictureUpdated = async pictureData => {
      croppedPicture.value = pictureData.croppedPicture
    }

    // =========================================
    // ui
    // =========================================
    const isDialogOpenSetinal = ref(false)

    const syncDialogVariables = value => {
      isDialogOpenSetinal.value = value
      context.emit('update:showDialog', value)
    }

    const isDialogOpen = computed({
      get() {
        return props.showDialog || isDialogOpenSetinal.value
      },
      set(newValue) {
        syncDialogVariables(newValue)
      },
    })

    const closeDialog = () => {
      syncDialogVariables(false)
      pictureEditSource.value = {
        profilePictureUrl: localInputModel.value.profilePictureUrl,
        firstLast: localInputModel.value.firstLast,
      }
    }

    // =========================================
    // use cases
    // =========================================

    // =========================================
    // lifecycle
    // =========================================

    // =========================================
    // return
    // =========================================
    return {
      // variables

      // properties
      localInputModel,
      localShowButton,
      localTitle,
      pictureEditSource,

      // reference data

      // feature
      handlePictureUpdated,

      // form
      submitForm,

      // ui
      isDialogOpenSetinal,
      isDialogOpen,
      closeDialog,
      avatarText,

      // use cases

      // standard
      validators: { },
      icons: {
        mdiPencilOutline,
      },
    }
  },
}
</script>

<template>
  <div
    id="contact-picture-edit-dialog"
  >
    <v-dialog
      v-model="isDialogOpen"
      max-width="650px"
      @click:outside="closeDialog"
    >
      <template
        v-if="localShowButton"
        #activator="{ on, attrs }"
      >
        <v-icon
          v-bind="attrs"
          v-on="on"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>

      <v-form
        ref="form"
        class="multi-col-validation"
        @submit.prevent="submitForm"
      >
        <v-card>
          <v-card-title class="justify-center text-h5">
            {{ localTitle }}
          </v-card-title>

          <v-card-text>
            <ContactPictureEdit
              :input-model="pictureEditSource"
              :allow-take-photo="allowTakePhoto"
              @picture-updated="handlePictureUpdated"
            />

            <!-- Save/Close -->
            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-center mt-3"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  type="submit"
                >
                  Save &amp; Close
                </v-btn>
                <v-btn
                  outlined
                  color="secondary"
                  class="me-3"
                  @click="closeDialog"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <pre>localInputModel: {{ localInputModel }}</pre> -->
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
// div {
//   border: 1px solid black;
// }
</style>
