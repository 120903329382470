<script>
import { computed } from '@vue/composition-api'
import { mdiBellOff, mdiClose } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    PerfectScrollbar,
    AppCardActions,
  },
  model: {
    prop: 'isNotificationSidebarActive',
    event: 'update:is-notification-sidebar-active',
  },
  props: {
    isNotificationSidebarActive: {
      type: Boolean,
      required: true,
    },
    list: {
      type: Array,
      required: false,
      default: (() => []),
    },
  },
  setup(props, context) {
    // =========================================
    // TYPE:            COMPONENT
    // PROP (BINDING)   1 WAY
    // DIRECT API:      NO
    // FORM DATA:       NO
    // =========================================

    // =========================================
    // error handling
    // =========================================

    // =========================================
    // user session context
    // =========================================

    // =========================================
    // properties
    // =========================================
    const localList = computed(() => JSON.parse(JSON.stringify(props.list)))

    // =========================================
    // notifications
    // =========================================
    const dismiss = async notificationId => {
      const notificationList = notificationId
        ? localList.value.filter(x => x.id === notificationId).map(x => x.id)
        : localList.value.map(x => x.id)

      context.emit('dismiss-notifications', notificationList)
    }

    // =========================================
    // ui
    // =========================================
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      passive: true,
    }

    // =========================================
    // return
    // =========================================
    return {
      // properties
      localList,

      // notifications
      dismiss,

      // ui
      perfectScrollbarOptions,
      uuidv4,

      // standard
      validators: {},
      icons: {
        mdiBellOff,
        mdiClose,
      },
    }
  },
}
</script>

<template>
  <div class="app-customizer">
    <v-navigation-drawer
      :value="isNotificationSidebarActive"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      @input="(val) => $emit('update:is-notification-sidebar-active', val)"
    >
      <!-- Bar -->
      <div class="app-customizer-header customizer-section py-3">
        <h6
          class="font-weight-semibold text-xl text--primary"
        >
          Notifications
        </h6>
        <span class="text--secondary"></span>

        <v-btn
          icon
          class="icon-customizer-close"
          @click="$emit('update:is-notification-sidebar-active', false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <!-- <pre>{{ localList }}</pre> -->

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!-- Notification Section - Title -->
        <div
          v-if="localList.length > 0"
          class="customizer-section"
        >
          <div class="d-flex justify-space-between align-center">
            <router-link
              :to="{ name: 'feature-activity-list', query: { activitySource: '1', activityType: '11', v: uuidv4() } }"
              class="text--primary text-xs text-truncate cursor-pointer text-decoration-none"
            >
              More events in activity log &#8594;
            </router-link>
            <v-btn
              small
              text
              plain
              class="py-0"
              @click.stop="dismiss(null)"
            >
              Dismiss All
            </v-btn>
          </div>

          <v-divider></v-divider>

          <!-- Notifications -->
          <app-card-actions
            v-for="notification in localList "
            :key="notification.id"
            action-remove
            class="my-6"
            @removed="dismiss(notification.id)"
          >
            <template slot="before-actions">
            </template>
            <template slot="title">
              <router-link
                :to="{ name: 'feature-activity-view', params: { id: notification.eventId }, query: { activitySource: 'eventlog' }, }"
                class="text-truncate cursor-pointer text-decoration-none"
              >
                {{ notification.subject }}
              </router-link>
            </template>

            <v-card-text class="text-truncate">
              {{ notification.message }}
            </v-card-text>
          </app-card-actions>
        </div>

        <div
          v-else
          class="text-center no-messages"
        >
          <v-icon
            size="80"
            color="primary"
          >
            {{ icons.mdiBellOff }}
          </v-icon>
          <div class="font-weight-semibold">
            No new notifications
          </div>
          <!-- <router-link
            :to="{ name: 'feature-activity-list', query: { activitySource: 1, ts: uuidv4() } }"
            class="primary--text text-truncate cursor-pointer text-decoration-none"
          >
            More events in activity log &#8594;
          </router-link> -->
        </div>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

// div {
//   border: 1px solid black;
// }

.no-messages{
  margin-top: 100px;
}

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 15 !important;
  position: absolute;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
